<div class="row">
  <div class="col-md-12 col-xl-12">
      <div class="card">
          <div class="card-header b-l-primary border-3">
              <button style="float: right;" type="button" class="btn btn-primary m-r-25" (click)="addNewInvoice();$event.preventDefault()"><i class="p-r-5 fa fa-plus"></i><span><strong>Add New Invoice</strong></span></button>
          </div>
          <div class="card-body">
              <div #tableWrapper style="padding-left:15px;padding-right:15px;">
                  <ngx-datatable
                      #DatatableComponent
                      class="ngx-datatable content-table bootstrap fixed-header virtualized"
                      [loadingIndicator]="loading"
                      [columnMode]="'force'"
                      [headerHeight]="bShowFilters ? 105 : 50"
                      [footerHeight]="50"
                      [rowHeight]="65"
                      [externalPaging]="true"
                      [externalSorting]="true"
                      [count]="page.count"
                      [offset]="page.offset"
                      [limit]="page.limit"
                      [sortType]="'single'"
                      [reorderable]="true"
                      (page)="pageCallback($event)"
                      (sort)="sortCallback($event)"
                      [sorts]="[{prop: 'id', dir: 'desc'}]"
                      (activate)="onActivate($event)"
                      [scrollbarH]="true"
                      [rows]="items"
                      >
                      <ngx-datatable-column prop="id" name="ID" [width]="10" [resizeable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.id}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="invoiceDate" name="Invoice Date" [width]="30" [resizeable]="true">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{moment(row.invoiceDate).format('DD MMM YYYY')}}</strong>
                          </ng-template>
                          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span class="datatable-header-cell-wrapper">
                              <span class="datatable-header-cell-label" (click)="sort()">{{'Invoice Date' | translate }}</span>
                            </span>
                            <div class="filter-background w-100">
                              <input [ngClass]="{'d-none':!bShowFilters}" id="dateRangeInput" class="form-control"
                                name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                outsideDays="collapsed" [startDate]="fromDate!" tabindex="-1" (click)="datepicker.toggle()"
                                placeholder="Select date range" container="body" />
                              <ng-template #t let-date let-focused="focused">
                                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                  [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                  (mouseleave)="hoveredDate = null">
                                  {{ date.day }}
                                </span>
                              </ng-template>
                            </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="tax" name="Invoiced By" [width]="100" [resizeable]="false">
                        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                            <strong>{{getReturnInvoicedBy(row)}}</strong>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="tax" name="Invoiced To" [width]="100" [resizeable]="false">
                        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                            <strong>{{getReturnInvoicedTo(row)}}</strong>
                        </ng-template>
                      </ngx-datatable-column>
                      
                      <ngx-datatable-column prop="createdAt" name="Created" [width]="30" [resizeable]="true">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{moment(row.createdAt).format('DD MMM YYYY')}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="updatedAt" name="Last Updated" [width]="30" [resizeable]="true">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                            <strong>{{moment(row.updatedAt).format('DD MMM YYYY')}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                  
                      <ngx-datatable-footer>
                          <ng-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
                            let-curPage="curPage" let-offset="offset" ngx-datatable-footer-template>
                            <div class="controls">
                              <div>
                                <label class="datatable-body-cell-label " for="per-page">
                                  {{'Items Per Page' | translate}}
                                </label>
                                <select id="items-per-page" class="form-control items-per-page"
                                  (change)="onLimitChange($event.target.value)" [value]="page.limit"
                                  style="display:inline-block;width: 50px;text-align: center;">
                                  <option *ngFor="let option of pageLimitOptions" [value]="option.value">
                                    {{ option.value }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <app-datatable-pager (download)="exportAs($event)" [page]="curPage" [visiblePagesCount]="3"
                              [size]="pageSize" [count]="rowCount" [hidden]="false" (showFilter)="showFilters()"
                              (clearFilter)="clearFilter()" [filtersShowing]="bShowFilters" [filterApplied]="bFilterApplied"
                              (change)="onPageChange($event)">
                            </app-datatable-pager>
                          </ng-template>
                        </ngx-datatable-footer>
                  </ngx-datatable>
              </div>
          </div>
      </div>
  </div>
</div>
