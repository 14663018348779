<div class="modal-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="card-body">
      <div *ngIf="employee == undefined else edit_employee" ><div class="ribbon ribbon-primary">Add New Employee</div></div>
      <ng-template #edit_employee><div class="ribbon ribbon-primary">Edit Employee Details</div></ng-template>
      <div class="row">
          <div class="col">
              <div class="card shadow shadow-showcase">
                  <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                  <div class="card-body row" style="margin-top:30px;">
                      <div class="col-sm-12 col-xl-12 form-group m-form__group">
                          <label >Employee Name</label>
                          <span class="text-danger">*</span>
                          <div class="input-group">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                              </div>
                              <input autofocus formControlName="name" type="text" class="form-control" placeholder="Name of the Employee" required>
                          </div>
                          <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                              <div *ngIf="form.controls.name.errors.required">
                                  Employee Name  is required
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-12 col-xl-12 form-group m-form__group">
                          <label >Mobile Number </label>
                          <span class="text-danger">*</span>
                          <div class="input-group">
                              <div class="input-group-prepend">
                                  <span class="input-group-text">+91</span>
                              </div>
                              <input autofocus formControlName="mobileNumber" type="text" class="form-control" placeholder="Format - XXXXXXXXXX" required maxlength=10 (keypress)="numberOnly($event)">
                          </div>
                          <div *ngIf="form.controls.mobileNumber.invalid && (form.controls.mobileNumber.dirty || form.controls.mobileNumber.touched)" class="text text-danger">
                              <div *ngIf="form.controls.mobileNumber.errors.required">
                                  Mobile Number is required
                              </div>
                              <div *ngIf="form.controls.mobileNumber.errors.pattern || form.controls.mobileNumber.errors.maxlength || form.controls.mobileNumber.errors.minlength " class="text text-danger">
                                  Mobile number must be 10 numbers
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-12 col-xl-12 form-group m-form__group">
                        <label >Role </label>
                        <span class="text-danger">*</span>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                            </div>
                            <select  formControlName="role" class="form-control digits">  
                                <option value="0">--Select--</option>  
                                <option value="Admin">Administrator</option>
                                <option value="SalesOfficer">Sales Officer</option>
                                <option value="FinanceOfficer">Finance Officer</option>
                                <option value="CallCenter">Call Center</option>

                            </select>
                        </div>
                        <div *ngIf="form.controls.role.invalid && (form.controls.role.dirty || form.controls.role.touched)" class="text text-danger">
                            <div *ngIf="form.controls.role.errors.required || form.controls.role.errors.notAllowed">
                                Role is required
                            </div>
                        </div>
                    </div>
                      <div class="col-sm-12 col-xl-12 form-group m-form__group">
                          <label >Active? </label>
                          <span class="text-danger">*</span>
                          <div class="input-group">
                              <input type="checkbox" formControlName="active"/>
                          </div>
                      </div>
                  </div>
                  <div class="card-footer" style="text-align:right;">
                      <button type="submit" class="btn btn-primary me-1">Save Employee Details</button>
                      <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Closed')">Cancel</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</form>