import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { AddProjectComponent } from '../add-project/add-project.component';
import { Dealer } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../app.service';
import { combineLatest, debounceTime, lastValueFrom, map, Subject, Subscription } from 'rxjs';
import { ColumnMode, DatatableComponent, NgxDatatableModule } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.scss']
})
export class ListProjectComponent extends ListBaseComponent<Dealer> implements OnInit {

  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('DatatableComponent') ngxDatatable: DatatableComponent;

  private currentComponentWidth
  
  projectRegistrationObserver: Subscription;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  bShowFilters = false

  employees:[] = []

  name:string = '';
  mobileNumber:string = '';
  activeFilter:string = '';
  selectedEmployee = "0"
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal) {
      super(http,breadcrumbService,appService,offcanvasService,router,route, ); 
  }

  ngOnInit(): void {
    
    this.appService.setTitle("All Project")
    this.appService.setMenuItems([{label:'All Project',disabled:true,routerLink: '/projects',iconClass:"pi pi-users"}])

    lastValueFrom(combineLatest([
      this.http.get<any>(`${environment.serverUrl}/employee`, { }),
     ]).pipe(
      map(([a]) => { 
        this.employees = a.data
      })
    ))
    
    this.pageCallback({ offset: 0 });

    this._message.subscribe((message) => (this.message = message));
    this._message.pipe(debounceTime(5000)).subscribe(() => {
			this.alertError = false;
		});
  }

  ngAfterViewChecked() {
    // Check if the table size has changed,
    if (this.ngxDatatable && this.ngxDatatable.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.ngxDatatable.columnMode = ColumnMode.force;
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.ngxDatatable.recalculate();
      
    }
  }

  showFilters(){
    this.bShowFilters = !this.bShowFilters
    if( !this.bShowFilters){
      this.clearFilter();
    }
    setTimeout(() => {
      this.ngxDatatable.recalculate()
    }, 100);
  }
  clearFilter(){
    
    this.mobileNumber = ""
    this.name = ""
    this.activeFilter = ""
    super.clearFilter();
  }

  applyFilterAfterDelay(){
    setTimeout(() => {
      this.applyFilter();
    }, 10); 
  }

  populateParamsForQuery(params: HttpParams): HttpParams{

    if( this.name.length > 0){
      params = params.set('name', `${this.name}`);
    }
    if( this.mobileNumber.length > 0){
      params = params.set('mobileNumber', `${this.mobileNumber}`);
    }
    
    if( this.activeFilter.length > 0 ){
      params = params.set('active', `${this.activeFilter}`);
    }

    if( this.selectedEmployee && this.selectedEmployee != "0" ){
      params = params.set('employeeId', `${this.selectedEmployee}`);
    }
    return params;
  }

  onActivate(event){
    if(event.type == 'click') {
      console.log(event.row);
      this.editDealer(event.row)
    }
  }

  addNewDealer(){
    
    const modalRef = this.modalService.open(AddProjectComponent,{ centered: true,size: 'lg'});
    modalRef.componentInstance.name = 'Add / Edit Project';
    
    if( this.projectRegistrationObserver ){
      this.projectRegistrationObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/

    this.projectRegistrationObserver = modalRef.componentInstance.projectEntry.subscribe((dealer) => {
      this.reloadTable()
    });
    
  }

  editDealer(project){
    
    this.router.navigate(['projects',project.id])
  }

  protected get loadingUrl(){
    return `${environment.serverUrl}/project`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/project/export`;
  }

  protected get exportFileName(){
    return `Project Report`;
  }

  getSalesOfficers(project){
    const names = project.employees?.map( em => em.name)
    return names.join(",")
  }

}
