<div *ngIf="loading" class="overlay">
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
</div>
<p-tabView>
  <p-tabPanel header="All Check-Ins">
    <div>
      <div class="row">
        <div class="col-md-12 col-xl-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-4">
                    <label class="p-l-10"  for="startDate">Period</label>
                    <input id="dateRangeInput" class="form-control"
                      name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
                      (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                      outsideDays="collapsed" [startDate]="fromDate!" tabindex="-1" (click)="datepicker.toggle()"
                      placeholder="Select date range" container="body" />
                      <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                          [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null">
                          {{ date.day }}
                        </span>
                      </ng-template>
                  </div>
                  <div class="col-md-4">
                    <label for="distributorOrDealer">Select Employee</label>
                    <select class="form-control" [(ngModel)]="selectedEmployee">
                      <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label class="p-l-10" for="startDate">&nbsp;</label>
                    <button class="btn btn-primary" (click)="applyFilter()" style="display:block;width: 200px;">Generate Report</button>
                  </div>
                </div>
              </div>
                <div class="card-body">
                    <div #tableWrapper style="padding-left:15px;padding-right:15px;">
                        <ngx-datatable
                            #DatatableComponent
                            class="ngx-datatable content-table bootstrap fixed-header virtualized"
                            [loadingIndicator]="loading"
                            [columnMode]="'force'"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            [rowHeight]="65"
                            [externalPaging]="true"
                            [externalSorting]="true"
                            [count]="page.count"
                            [offset]="page.offset"
                            [limit]="page.limit"
                            [sortType]="'single'"
                            [reorderable]="true"
                            (page)="pageCallback($event)"
                            (sort)="sortCallback($event)"
                            [sorts]="[{prop: 'id', dir: 'desc'}]"
                            [scrollbarH]="true"
                            selectionType="single"
                            [rows]="items"
                            >
                            <ngx-datatable-column prop="id" name="ID" [width]="10" [resizeable]="false">
                                <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                    <strong>{{row.id}}</strong>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="name" name="Sales Officer" [width]="30" [resizeable]="true">
                                <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                    <strong>{{row.employee?.name}}</strong>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="name" name="Date" [width]="30" [resizeable]="true">
                              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                  <strong>{{row.checkinDate ? moment(row.checkinDate).format("DD MMM yyyy") :"N/A"}}</strong>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="mobileNumber" name="Type" [width]="30" [resizeable]="true">
                              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.userCheckinType}}</strong>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="mobileNumber" name="Dealer" [width]="30" [resizeable]="true">
                              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.dealer?.name}}</strong>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="mobileNumber" name="Distributor" [width]="30" [resizeable]="true">
                              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.distributor?.name}}</strong>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="createdAt" name="Lead Name" [width]="30" [resizeable]="true">
                                <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                    <strong>{{row.name}}</strong>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="updatedAt" name="Lead Contact" [width]="30" [resizeable]="true">
                                <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                    <strong>{{row.phoneNumber}}</strong>
                                </ng-template>
                            </ngx-datatable-column>
                        
                            <ngx-datatable-footer>
                                <ng-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
                                  let-curPage="curPage" let-offset="offset" ngx-datatable-footer-template>
                                  <div class="controls">
                                    <div>
                                      <label class="datatable-body-cell-label " for="per-page">
                                        {{'Items Per Page' | translate}}
                                      </label>
                                      <select id="items-per-page" class="form-control items-per-page"
                                        (change)="onLimitChange($event.target.value)" [value]="page.limit"
                                        style="display:inline-block;width: 50px;text-align: center;">
                                        <option *ngFor="let option of pageLimitOptions" [value]="option.value">
                                          {{ option.value }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <app-datatable-pager (download)="exportAs($event)" [page]="curPage" [visiblePagesCount]="3"
                                    [size]="pageSize" [count]="rowCount" [hidden]="false" (showFilter)="showFilters()"
                                    (clearFilter)="clearFilter()" [filtersShowing]="bShowFilters" [filterApplied]="bFilterApplied"
                                    (change)="onPageChange($event)">
                                  </app-datatable-pager>
                                </ng-template>
                              </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
    </div>
  </p-tabPanel>
  <p-tabPanel header="In Sufficient Check-ins ">
    <div>
      <div class="row">
        <div class="col-md-12 col-xl-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-4">
                    <label class="p-l-10"  for="startDate">Period</label>
                    <input id="dateRangeInputInSufficient" class="form-control"
                      name="datepicker2" ngbDatepicker #datepicker2="ngbDatepicker" [autoClose]="'outside'"
                      (dateSelect)="onDateSelectionInSufficient($event)" [displayMonths]="2" [dayTemplate]="t2"
                      outsideDays="collapsed" [startDate]="fromDateInSufficient!" tabindex="-1" (click)="datepicker2.toggle()"
                      placeholder="Select date range" container="body" />
                      <ng-template #t2 let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRangeInSufficient(date)"
                          [class.faded]="isHoveredInSufficient(date) || isInsideInSufficient(date)" (mouseenter)="hoveredDateInSufficient = date"
                          (mouseleave)="hoveredDateInSufficient = null">
                          {{ date.day }}
                        </span>
                      </ng-template>
                  </div>
                  <div class="col-md-4">
                    <label for="distributorOrDealer">Enter Minimum Check-In Count</label>
                    <input class="form-control" [(ngModel)]="minimumCheckInCount" />
                    
                  </div>
                  <div class="col-md-4">
                    <label class="p-l-10" for="startDate">&nbsp;</label>
                    <button class="btn btn-primary" (click)="generateInSufficientCheckIns()" style="display:block;width: 200px;">Generate Report</button>
                  </div>
                </div>
              </div>
                <div class="card-body">
                  <p-table
                    styleClass="p-datatable-gridlines" 
                    [value]="checkinData"
                    [scrollable]="true"
                    [scrollHeight]="'600px'"
                  >
                    <!-- Frozen Columns -->
                    <ng-template pTemplate="header">
                      <tr>
                        <th pFrozenColumn [style]="{ width: '250px', minWidth: '250px', maxWidth: '250px', textAlign:'center' }">
                          Name
                        </th>
                        <th *ngFor="let col of columns.slice(1)" [style]="{ width: '150px', minWidth: '150px', maxWidth: '150px', textAlign:'center' }">
                          {{ col.header }}
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr>
                        <td pFrozenColumn style="z-index: 1; width: 200px;">
                          {{ rowData['employeeName'] }}
                        </td>
                        <td *ngFor="let col of columns.slice(1)" [style]="{ width: '150px', minWidth: '150px', maxWidth: '150px', textAlign:'center' }">
                          {{ rowData[col.field] || 0 }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>

                  <div *ngIf="checkinData && checkinData.length > 0" style="text-align: right; padding-top:10px">
                    <button 
                        type="button" 
                        pButton 
                        pRipple 
                        icon="pi pi-file-export" 
                        (click)="exportInSufficientCheckInsAsExcel()" 
                        label="Export As Excel" 
                        class="p-button-text">
                    </button>
                  </div>

                </div>
            </div>
        </div>
      </div>
      
    </div>
  </p-tabPanel>
</p-tabView>