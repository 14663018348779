import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  
  private title = new BehaviorSubject<string>('App title');
  private title$ = this.title.asObservable();

  private menuItems = new BehaviorSubject<MenuItem[]>([]);
  private menuItems$ = this.menuItems.asObservable();

  constructor() {}

  setTitle(title: string) {
    this.title.next(title);
  }

  getTitle(): Observable<string> {
    return this.title$;
  }

  getMenuItems():Observable<MenuItem[]> {
    return this.menuItems$;
  }

  setMenuItems(items: MenuItem[]){
    this.menuItems.next(items);
  }
}