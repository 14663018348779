import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { LookupData, RedeemRequest } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../app.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ColumnMode, DatatableComponent, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RedeemRequestDetailsComponent } from '../redeem-request-details/redeem-request-details.component';
import { fileURLToPath } from 'url';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { IS_UPLOAD_REQUEST, Product, ProductCategory } from 'src/app/types/types';
import { ToastrService } from 'ngx-toastr';
import { AddContractorComponent } from '../../contractor/add-contractor/add-contractor.component';
declare var require
const Swal = require('sweetalert2')

interface AnyResponse {
  data: any[];
  count: number;
}

@Component({
  selector: 'app-list-redeem-requests',
  templateUrl: './list-redeem-requests.component.html',
  styleUrls: ['./list-redeem-requests.component.scss']
})
export class ListRedeemRequestsComponent extends ListBaseComponent<RedeemRequest> implements OnInit {

  moment = moment

  @ViewChild('attachments') attachments: ElementRef;
  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('DatatableComponent') ngxDatatable: DatatableComponent;
  private currentComponentWidth

  contractorRegistrationObserver: Subscription;
  
  redeemRequesdtObserver: Subscription

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  products = []
  
  bShowFilters = false
  customerName = ''
  mobileNumber = ''
  transactionNumber = ''
  productId : number = 0
  amount = ''
  requestStatus = 'all'
  fromDate: NgbDate | null;
	toDate: NgbDate | null;

  hoveredDate: NgbDate | null = null;
  selectedYear: string = "0";

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,private toastrService: ToastrService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute,private modalService: NgbModal, protected formatter: NgbDateParserFormatter,protected renderer: Renderer2) {
      super(http,breadcrumbService,appService,offcanvasService,router,route); 
     }

  ngOnInit(): void {
    this.fromDate = undefined;
		this.toDate = undefined;
    this.page = {
      limit: 10,
      count: 0,
      offset: 0,
      orderBy: 'id',
      orderDir: 'desc'
    };
    this.pageCallback({ offset: 0 });

    this._message.subscribe((message) => (this.message = message));
    this._message.pipe(debounceTime(5000)).subscribe(() => {
			this.alertError = false;
		});

    this.http.get<any[]>(`${environment.serverUrl}/product`).subscribe({
      next: (products: any) => {
        this.products = products.data
      },
      error: (e) => {
        console.error(e);
        this.showMessage("error",`Loading Products failed. Message:${e.message}`);
        this.loading = false;  
      }
    });
  }

  ngAfterViewChecked() {
    // Check if the table size has changed,
    if (this.ngxDatatable && this.ngxDatatable.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.ngxDatatable.columnMode = ColumnMode.force;
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.ngxDatatable.recalculate();
      
    }
  }

  showFilters(){
    this.bShowFilters = !this.bShowFilters
    if( !this.bShowFilters){
      this.clearFilter();
    }
    setTimeout(() => {
      this.ngxDatatable.recalculate()
    }, 100);
  }

  clearFilter(){
    const dateRangeInput = document.getElementById("dateRangeInput");
    this.mobileNumber = ""
    this.customerName = ""
    this.transactionNumber = ""
    this.productId = 0
    this.amount = ""
    this.requestStatus = "all"
    this.fromDate = undefined
    this.toDate = undefined
    if( dateRangeInput )
      this.renderer.setProperty(dateRangeInput, 'value', "");
    super.clearFilter();
  }

  applyFilterAfterDelay(){
    setTimeout(() => {
      this.applyFilter();
    }, 10); 
  }

  populateParamsForQuery(params: HttpParams): HttpParams{

    if( this.mobileNumber.length > 0){
      params = params.set('mobileNumber', `${this.mobileNumber}`);
    }
    if( this.customerName.length > 0){
      params = params.set('customerName', `${this.customerName}`);
    }
    if( this.transactionNumber.length > 0){
      params = params.set('transactionNumber', `${this.transactionNumber}`);
    }
    if( this.productId > 0){
      params = params.set('productId', `${this.productId}`);
    }
    if( this.amount.length > 0){
      params = params.set('amount', `${this.amount}`);
    }
    if( this.requestStatus.length > 0 && this.requestStatus !== "all"){
      params = params.set('requestStatus', `${this.requestStatus}`);
    }
    if( this.fromDate && this.toDate){
      params = params.set('fromDate', `${this.formatter.format(this.fromDate)}`);
      params = params.set('toDate', `${this.formatter.format(this.toDate)}`);
    }
    return params;
  }

  exportPendingAndVerified(){

    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/vnd.ms-excel'}),
      params: {},
      responseType: 'blob'
    };
    this.http.get<Blob>(`${this.loadingUrl}/export-for-bank`,httpOptions)
    .subscribe(blob => {
      console.log(blob)
      saveAs(new Blob([blob], { type: 'vnd.ms-excel;charset=utf-8' }), `bank-transfers-${moment().format("BULK_DDMMYY_TOK001")}.xlsx`)
      this.reloadTable()
     });
  }

  importBankFile(){
    document.getElementById('bankExcelSheet').click();
  }

  editCustomer(customer){
    const modalRef = this.modalService.open(AddContractorComponent,{ centered: true,size: 'lg' });
    modalRef.componentInstance.name = 'Add / Edit Customer';
    
    if( this.contractorRegistrationObserver ){
      this.contractorRegistrationObserver.unsubscribe();
    }
  
    modalRef.componentInstance.customer = customer;

    this.contractorRegistrationObserver = modalRef.componentInstance.contractorEntry.subscribe((contractor) => {
      this.reloadTable()
    });
  }
  async uploadExcelSheet(event): Promise<void> {
    if (event.target.files.length === 0)
      return;
    const formData: FormData = new FormData();    
    
    formData.append("file",event.target.files[0]);

    this.http.post(`${environment.serverUrl}/redeem-request/import-from-bank`, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
      next: (v:any) => {
        console.log(v)
        this.reloadTable()
        this.attachments.nativeElement.value = "";
        this.toastrService.success(`Successfully updated ${v.successRecords} records`,'Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
      },
      error: (e) => {
        console.error(e);
        this.attachments.nativeElement.value = "";
        this.toastrService.error(`Error upddating records. Message:${e.message}`,'Error',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
      },
      complete: () => console.info('complete')
    });
  }

  markAsUnPaid(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to change status back to Unpaid?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {

        
        this.http.post(`${environment.serverUrl}/redeem-request/change-status-to-unpaid`, JSON.stringify({redeeemRequestId:id, status:"Open"}))
        .subscribe({
          next: (v) => {
            this.reloadTable()
            Swal.fire(
              'Success!',
              'Updated Successfully',
              'success'
            )
          },
          error: (e) => {
            Swal.fire(
              'Error!',
              e.message,
              'error'
            )
            
          }
        });

        
      }
    })
  }

  markAsPaid(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to change status back to Paid?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.http.post(`${environment.serverUrl}/redeem-request/change-status-to-unpaid`, JSON.stringify({redeeemRequestId:id, status:"Paid"}))
        .subscribe({
          next: (v) => {
            this.reloadTable()
            Swal.fire(
              'Success!',
              'Updated Successfully',
              'success'
            )
          },
          error: (e) => {
            Swal.fire(
              'Error!',
              e.message,
              'error'
            )
          }
        });
      }
    })
  }
  onActivate(event){
    if(event.type == 'click' && event.cellIndex != 8 && event.cellIndex != 1) {
      console.log(event.row);
      const modalRef = this.modalService.open(RedeemRequestDetailsComponent,{ centered: true,size: 'xl' });
      
      if( this.redeemRequesdtObserver ){
        this.redeemRequesdtObserver.unsubscribe();
      }
    
      modalRef.componentInstance.redeemRequest = event.row;

      this.redeemRequesdtObserver = modalRef.componentInstance.redeemRequestEntry.subscribe((redeemRequest) => {
        this.reloadTable()
      });
    }
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  getRowClass = (row) => {
    if( row.requestStatus === 'Paid'){
      return 'row-unused'
    }
    else if( row.requestStatus === 'Open'){
      if( !row.customer.verified ) return 'customer-unverified'
      return 'row-used'
    }
    if( !row.customer.verified ) return 'customer-unverified'
    return 'row-sent-to-bank'
   }

  protected get loadingUrl(){
    return `${environment.serverUrl}/redeem-request/contractor`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/redeem-request/contractor/export`;
  }

  protected get exportFileName(){
    return `Enquiry Report`;
  }

  onYearChanged(selectedYear){
    this.selectedYear = selectedYear;

  }

  onDateSelection(date: NgbDate) {
    const dateFilterApplied = this.fromDate && this.toDate ? true : false
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
    let parsed = '';
    if(this.fromDate && this.toDate) {
      parsed += this.formatter.format(this.fromDate);
      parsed += ' to ' + this.formatter.format(this.toDate);
    }
    const dateRangeInput = document.getElementById("dateRangeInput");
    this.renderer.setProperty(dateRangeInput, 'value', parsed);
    if( dateFilterApplied ){
      this.applyFilter();
      return
    }
    if( this.fromDate && this.toDate ){
      this.applyFilter();
    }
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}
}
