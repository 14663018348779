import { InvoiceDetailComponent } from './../../modules/invoice/invoice-detail/invoice-detail.component';
import { ListInvoiceComponent } from './../../modules/invoice/list-invoice/list-invoice.component';
import { ListDealerComponent } from './../../modules/dealer/list-dealer/list-dealer.component';
import { DealerDetailComponent } from './../../modules/dealer/dealer-detail/dealer-detail.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivateRoutesRoutingModule } from './private-routes-routing.module';
import { Route, Router, Routes } from '@angular/router';
import { NavService, Menu } from '../../services/nav-service.service';
import { LoginComponent } from '../../auth/login/login.component';
import { ContentLayoutComponent } from '../../shared/components/layout/content-layout/content-layout.component';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import { AuthService } from '../../services/auth-service.service';
import { Error404Component } from '../../pages/error-pages/error404/error404.component';
import { User } from '../../types/types';
import { map, pipe, Subscription, tap } from 'rxjs';
import { DashboardModule } from 'src/app/modules/dashboard/dashboard.module';
import { DefaultComponent } from 'src/app/modules/dashboard/default/default.component';
import { HttpErrorResponse } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { ListContractorComponent } from '../../modules/contractor/list-contractor/list-contractor.component';
import { ListDistributorComponent } from '../../modules/distributor/list-distributor/list-distributor.component';
import { ListProductComponent } from '../../modules/product/list-product/list-product.component';
import { ListQrCodeBatchComponent } from '../../modules/qrcodes/list-qr-code-batch/list-qr-code-batch.component';
import { ListRedeemRequestsComponent } from '../../modules/redeem-requests/list-redeem-requests/list-redeem-requests.component';
import { RedeemRequestDetailsComponent } from '../../modules/redeem-requests/redeem-request-details/redeem-request-details.component';
import { DistributorDetailComponent } from 'src/app/modules/distributor/distributor-detail/distributor-detail.component';
import { EmployeeDetailComponent } from 'src/app/modules/employee/employee-detail/employee-detail.component';
import { ListEmployeeComponent } from 'src/app/modules/employee/list-employee/list-employee.component';
import { ListReturnInvoiceComponent } from 'src/app/modules/product-returns/list-product-returns/list-product-returns.component';
import { ListCouponPaymentsComponent } from '../../modules/coupon-payments/list-coupon-payments/list-coupon-payments.component';
import { CouponPaymentDetailsComponent } from '../../modules/coupon-payments/coupon-payment-details/coupon-payment-details.component';
import { CumulativeReportComponent } from 'src/app/modules/checkin/cumulative-report/cumulative-report.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrivateRoutesRoutingModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class PrivateRoutesModule {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService, private authService: AuthService) {
    const routes: Routes = [
      {
        path: 'auth/login',
        component: LoginComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: '',
        component: ContentLayoutComponent,
        pathMatch:'prefix',
        canActivate: [FlytAuthGuard],
        children: [
          {
            title: 'Dashboard', 
            path: 'dashboard',
            canActivate: [FlytAuthGuard],
            component: DefaultComponent,
            data: {
              breadcrumb: {
                label: 'Dashboard',
              },
              active: true,
              type: 'link',
              icon: 'home',
              role: 'Admin,Distributor',
              animation: 'slideInAnimation'
            },
          },
          {
            title: 'All Redeem Requests',
            path: 'redeem-requests',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListRedeemRequestsComponent,
                data: {
                  title:"All Redeem Requests",
                  breadcrumb: {
                    label: "All Redeem Requests",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: ':id',
                canActivate: [FlytAuthGuard],
                component: RedeemRequestDetailsComponent,
                data: {
                  title:"Enquiry Details",
                  breadcrumb: {
                    label: "Enquiry Details",
                    info: 'icofont icofont-files',
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              }
            ]
          },
          {
            title: 'All Coupon Payments',
            path: 'coupon-payments',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListCouponPaymentsComponent,
                data: {
                  title:"All Coupon Payments",
                  breadcrumb: {
                    label: "All Coupon Payments",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: ':id',
                canActivate: [FlytAuthGuard],
                component: CouponPaymentDetailsComponent,
                data: {
                  title:"Enquiry Details",
                  breadcrumb: {
                    label: "Enquiry Details",
                    info: 'icofont icofont-files',
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              }
            ]
          },
          {
            title: 'All Invoices',
            path: 'invoices',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListInvoiceComponent,
                data: {
                  title:"All Invoices",
                  breadcrumb: {
                    label: "All Invoices",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin,Distributor',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: ':id',
                canActivate: [FlytAuthGuard],
                component: InvoiceDetailComponent,
                data: {
                  title:"Invoice Details",
                  breadcrumb: {
                    label: "Invoice Details",
                    info: 'icofont icofont-files',
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              }
            ]
          },
          {
            title: 'All Returns',
            path: 'product-returns',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListReturnInvoiceComponent,
                data: {
                  title:"All Returns",
                  breadcrumb: {
                    label: "All Returns",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin,Distributor',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: ':id',
                canActivate: [FlytAuthGuard],
                component: InvoiceDetailComponent,
                data: {
                  title:"Invoice Details",
                  breadcrumb: {
                    label: "Invoice Details",
                    info: 'icofont icofont-files',
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              }
            ]
          },
          {
            title: 'All Contractors', 
            path: 'contractors',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListContractorComponent,
                data: {
                  title:"All Contractors",
                  breadcrumb: {
                    label: "All Contractors",
                    info: 'icofont icofont-files'
                  },
                  icon: 'file-plus',
                  type: 'link',
                  active: false,
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              }
            ]
          },
          {
            title: 'All Employees', 
            path: 'employees',
            canActivate: [FlytAuthGuard],
            data: {
              title:":id",
              breadcrumb: {
                label: "All Employees",
                info: 'icofont icofont-files'
              },
              icon: 'file-plus',
              type: 'link',
              active: false,
              role: 'Admin',
              animation: 'slideInAnimation'
            },
            children: [
              {
                path: ':id',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: EmployeeDetailComponent,
                data: {
                  title:":id",
                  breadcrumb: {
                    label: ":id",
                    info: 'icofont icofont-files'
                  },
                  icon: 'file-plus',
                  type: 'link',
                  active: false,
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListEmployeeComponent,
                data: {
                  title:"All Employees",
                  breadcrumb: {
                    label: "All Employees",
                    info: 'icofont icofont-files'
                  },
                  icon: 'file-plus',
                  type: 'link',
                  active: false,
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },

            ]
          },
          {
            title: 'All Distributors', 
            path: 'distributors',
            canActivate: [FlytAuthGuard],
            data: {
              title:":id",
              breadcrumb: {
                label: "All Distributors",
                info: 'icofont icofont-files'
              },
              icon: 'file-plus',
              type: 'link',
              active: false,
              role: 'Admin',
              animation: 'slideInAnimation'
            },
            children: [
              {
                path: ':id',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: DistributorDetailComponent,
                data: {
                  title:":id",
                  breadcrumb: {
                    label: ":id",
                    info: 'icofont icofont-files'
                  },
                  icon: 'file-plus',
                  type: 'link',
                  active: false,
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListDistributorComponent,
                data: {
                  title:"All Distributors",
                  breadcrumb: {
                    label: "All Distributors",
                    info: 'icofont icofont-files'
                  },
                  icon: 'file-plus',
                  type: 'link',
                  active: false,
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },

            ]
          },
          {
            title: 'All Dealers', 
            path: 'dealers',
            canActivate: [FlytAuthGuard],
            data: {
              title:":id",
              breadcrumb: {
                label: "All Dealers",
                info: 'icofont icofont-files'
              },
              icon: 'file-plus',
              type: 'link',
              active: false,
              role: 'Admin',
              animation: 'slideInAnimation'
            },
            children: [
              {
                path: ':id',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: DealerDetailComponent,
                data: {
                  title:":id",
                  breadcrumb: {
                    label: ":id",
                    info: 'icofont icofont-files'
                  },
                  icon: 'file-plus',
                  type: 'link',
                  active: false,
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListDealerComponent,
                data: {
                  title:"All Dealers",
                  breadcrumb: {
                    label: "All Dealers",
                    info: 'icofont icofont-files'
                  },
                  icon: 'file-plus',
                  type: 'link',
                  active: false,
                  role: 'Admin',
                  animation: 'slideInAnimation'
                }
              },

            ]
          },
          {
            title: 'All Products',
            path: 'products',
              canActivate: [FlytAuthGuard],
              children: [
                {
                  path: '',
                  pathMatch: 'full',
                  canActivate: [FlytAuthGuard],
                  component: ListProductComponent,
                  data: {
                    title:"All Products",
                    breadcrumb: {
                      label: "All Products",
                      info: 'icofont icofont-files'
                    },
                    icon: 'file-plus',
                    type: 'link',
                    active: false,
                    role: 'Admin',
                    animation: 'slideInAnimation'
                  }
                }
              ]
          },
          {
            title: 'All QRCode Batches',
            path: 'qrcode-batches',
              canActivate: [FlytAuthGuard],
              children: [
                {
                  path: '',
                  pathMatch: 'full',
                  canActivate: [FlytAuthGuard],
                  component: ListQrCodeBatchComponent,
                  data: {
                    title:"All QRCode Batches",
                    breadcrumb: {
                      label: "All QRCode Batches",
                      info: 'icofont icofont-files'
                    },
                    icon: 'file-plus',
                    type: 'link',
                    active: false,
                    role: 'Admin',
                    animation: 'slideInAnimation'
                  }
                }
              ]
          },
          {
            title: 'Check-ins',
            path: 'check-ins',
              canActivate: [FlytAuthGuard],
              children: [
                {
                  path: 'cumulative',
                  pathMatch: 'full',
                  canActivate: [FlytAuthGuard],
                  component: CumulativeReportComponent,
                  data: {
                    title:"Cumulative Check-ins",
                    breadcrumb: {
                      label: "All QRCode Batches",
                      info: 'icofont icofont-files'
                    },
                    icon: 'file-plus',
                    type: 'link',
                    active: false,
                    role: 'Admin',
                    animation: 'slideInAnimation'
                  }
                }
              ]
          },
        ],
        data: {
          role: 'Admin,Distributor',
          breadcrumb: {
            label: 'Home',
          }
        }
      },
      {
        path: 'error',
        loadChildren: () => import('../../pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: '**',
        component: Error404Component,
      }
    ];      
      this.router.resetConfig(routes); 
  }
}
