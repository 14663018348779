<div *ngIf="loading" class="overlay">
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
</div>
<div class="section-container">
  <h2 class="section-title p-b-15">Export Dealer Stock (Excel file)</h2>
  <div class="section-content">
      <p>Downloads an Excel file with dealer running stock</p>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <select class="form-control" [(ngModel)]="selectedEmployee">
            <option value="0">Select Employee</option>
            <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
          </select>
        </div>
        <div class="col-md-4"></div>
      </div>
      <br>
      <button id="importButton" (click)="downloadDealerStock()">DOWNLOAD</button>
      <p id="statusMessage"></p>
  </div>
</div>

<div class="section-container">
  <h2 class="section-title p-b-15">Export Distributor Stock (Excel file)</h2>
  <div class="section-content">
      <p>Downloads an Excel file with distributor running stock</p>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <select class="form-control" [(ngModel)]="selectedEmployeeForDistributor">
            <option value="0">Select Employee</option>
            <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
          </select>
        </div>
        <div class="col-md-4"></div>
      </div>
      <br>
      <button id="importButton" (click)="downloadDistributorStock()">DOWNLOAD</button>
      <p id="statusMessage"></p>
  </div>
</div>