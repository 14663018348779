import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { combineLatest, lastValueFrom, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import 'chartjs-adapter-moment';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AppService } from 'src/app/app.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ListBaseComponent } from 'src/app/components/list-base/list-base.component';
import { Employee } from 'src/app/types/types';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import Helper from 'src/app/helper/Helper';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-cumulative-report',
  templateUrl: './cumulative-report.component.html',
  styleUrls: ['./cumulative-report.component.scss']
})
export class AllCheckInReportComponent extends ListBaseComponent<Employee> implements OnInit {

  moment = moment

  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('DatatableComponent') ngxDatatable: DatatableComponent;

  private currentComponentWidth
  
  loading = false
  employees:[] = []
  selectedEmployee: string; // Default to distributor

  checkIns:[] = []
  inSufficientCheckIns:[] = []

  checkinData: any[] = []; // Array of rows (employees and their check-in counts)
  columns: any[] = []; // Array of columns (dates)

  limit: number = 10; // Default 

  hoveredDate: NgbDate | null = null;

	fromDate: NgbDate | null;

	toDate: NgbDate | null;

  hoveredDateInSufficient: NgbDate | null = null;

	fromDateInSufficient: NgbDate | null;

	toDateInSufficient: NgbDate | null;

  minimumCheckInCount = 10

  constructor(protected formatter: NgbDateParserFormatter, protected renderer: Renderer2, protected calendar: NgbCalendar, protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal) {
    super(http,breadcrumbService,appService,offcanvasService,router,route, ); 
  }

  ngOnInit(): void {
    lastValueFrom(combineLatest([
      this.http.get<any>(`${environment.serverUrl}/employee`, { }),
     ]).pipe(
      map(([a]) => { 
        this.employees = a.data
      })
    ))

    this.appService.setTitle("All Check-Ins")
    this.appService.setMenuItems([{label:'All Check-Ins',disabled:true,routerLink: '/dealers',iconClass:"pi pi-users"}])
  }

  ngAfterViewChecked() {
    // Check if the table size has changed,
    if (this.ngxDatatable && this.ngxDatatable.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.ngxDatatable.columnMode = ColumnMode.force;
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.ngxDatatable.recalculate();
      
    }
  }

  generateInSufficientCheckIns(){
    let params = new HttpParams()
    if( this.fromDateInSufficient){
      params = params.set('startDate', `${this.convertNgbDateToMoment(this.fromDateInSufficient).format("yyyy-MM-DD")}`);
    }

    if( this.toDateInSufficient){
      params = params.set('endDate', `${this.convertNgbDateToMoment(this.toDateInSufficient).format("yyyy-MM-DD")}`);
    }

    if( this.minimumCheckInCount){
      params = params.set('minimumCheckInCount', `${this.minimumCheckInCount}`);
    }
    this.loading = true

    this.http.get<any>(`${environment.serverUrl}/check-in/insufficient-checkins`, { params }).subscribe((response: any) => {
      this.loading = false
      const groupedData = response.data;
      if (!groupedData || typeof groupedData !== 'object') {
        console.error('Invalid grouped data format:', groupedData);
        return;
      }

      const allDates = Object.keys(groupedData)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()); // Sort dates in ascending order

      const employeeMap: { [employeeId: number]: any } = {};

      // Safely iterate over grouped data
      allDates.forEach((date) => {
        const records = groupedData[date] || []; // Default to an empty array if undefined
        if (!Array.isArray(records)) {
          console.error(`Invalid data for date ${date}:`, records);
          return;
        }

        records.forEach((record: any) => {
          if (!employeeMap[record.employeeId]) {
            employeeMap[record.employeeId] = { employeeName: record.employeeName };
          }
          employeeMap[record.employeeId][date] = record.checkinCount;
        });
      });

      // Convert the map to an array for PrimeNG table
      this.checkinData = Object.values(employeeMap);

      // Create columns for PrimeNG table
      this.columns = [
        { field: 'employeeName', header: 'Employee Name' },
        ...allDates.map((date) => ({ field: date, header: date }))
      ];

    });
  }

  showFilters(){
  }

  clearFilter(){
    
    super.clearFilter();
  }

  applyFilterAfterDelay(){
    setTimeout(() => {
      this.applyFilter();
    }, 10); 
  }

  protected downloadXLS(){
    let params = new HttpParams()
    .set('orderBy', `${this.page.orderBy}`)
    .set('orderDir', `${this.page.orderDir}`)
    .set('page', `${this.page.offset}`)
    .set('size', 1000000);

    try{
      params = this.populateParamsForQuery(params);
    }
    catch(error){
      return;
    }

    params = params.set('type', `XLS`)
    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/vnd.ms-excel'}),
      params: params,
      responseType: 'blob'
    };
    this.loading = true
    this.http.get<Blob>(`${environment.serverUrl}/check-in/by-employee/export`, httpOptions).subscribe((blob) => {
      this.loading = false
      saveAs(new Blob([blob], { type: 'vnd.ms-excel;charset=utf-8' }), `${this.exportFileName}.xlsx`)
    },
    (error) => {
      this.loading = false
      console.error('Error during file download:', error);
    });
  }

  exportInSufficientCheckInsAsExcel(){

    let params = new HttpParams()
    if( this.fromDateInSufficient){
      params = params.set('startDate', `${this.convertNgbDateToMoment(this.fromDateInSufficient).format("yyyy-MM-DD")}`);
    }

    if( this.toDateInSufficient){
      params = params.set('endDate', `${this.convertNgbDateToMoment(this.toDateInSufficient).format("yyyy-MM-DD")}`);
    }

    if( this.minimumCheckInCount){
      params = params.set('minimumCheckInCount', `${this.minimumCheckInCount}`);
    }

    params = params.set('type', `XLS`)
    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/vnd.ms-excel'}),
      params: params,
      responseType: 'blob'
    };
    this.loading = true
    this.http.get<Blob>(`${environment.serverUrl}/check-in/insufficient-checkins/export`, httpOptions).subscribe((blob) => {
      this.loading = false
      saveAs(new Blob([blob], { type: 'vnd.ms-excel;charset=utf-8' }), `${this.exportFileName}.xlsx`)
    },
    (error) => {
      this.loading = false
      console.error('Error during file download:', error);
    });

  }

  populateParamsForQuery(params: HttpParams): HttpParams{
    
    if( !this.fromDate || !this.toDate ){
      Helper.showMessageAlert(`Error!`,"Date period is mandatory",`error`)
      throw new Error('message');
    }
    if( this.fromDate){
      params = params.set('startDate', `${this.convertNgbDateToMoment(this.fromDate).format("DD-MM-yyyy")}`);
    }

    if( this.toDate){
      params = params.set('endDate', `${this.convertNgbDateToMoment(this.toDate).format("DD-MM-yyyy")}`);
    }

    if( this.selectedEmployee){
      params = params.set('employeeId', `${this.selectedEmployee}`);
    }
    return params;
  }

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
    let parsed = '';
    if(this.fromDate && this.toDate) {
      parsed += this.convertNgbDateToMoment(this.fromDate).format("DD MMM yyyy");
      parsed += ' to ' + this.convertNgbDateToMoment(this.toDate).format("DD MMM yyyy");
    }
    const dateRangeInput = document.getElementById("dateRangeInput");
    this.renderer.setProperty(dateRangeInput, 'value', parsed);
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

  onDateSelectionInSufficient(date: NgbDate) {
		if (!this.fromDateInSufficient && !this.toDateInSufficient) {
			this.fromDateInSufficient = date;
		} else if (this.fromDateInSufficient && !this.toDateInSufficient && date && date.after(this.fromDateInSufficient)) {
			this.toDateInSufficient = date;
		} else {
			this.toDateInSufficient = null;
			this.fromDateInSufficient = date;
		}
    let parsed = '';
    if(this.fromDateInSufficient && this.toDateInSufficient) {
      parsed += this.convertNgbDateToMoment(this.fromDateInSufficient).format("DD MMM yyyy");
      parsed += ' to ' + this.convertNgbDateToMoment(this.toDateInSufficient).format("DD MMM yyyy");
    }
    const dateRangeInput = document.getElementById("dateRangeInputInSufficient");
    this.renderer.setProperty(dateRangeInput, 'value', parsed);
	}

	isHoveredInSufficient(date: NgbDate) {
		return (
			this.fromDateInSufficient && !this.toDateInSufficient && this.hoveredDateInSufficient && date.after(this.fromDateInSufficient) && date.before(this.hoveredDateInSufficient)
		);
	}

	isInsideInSufficient(date: NgbDate) {
		return this.toDateInSufficient && date.after(this.fromDateInSufficient) && date.before(this.toDateInSufficient);
	}

	isRangeInSufficient(date: NgbDate) {
		return (
			date.equals(this.fromDateInSufficient) ||
			(this.toDateInSufficient && date.equals(this.toDateInSufficient)) ||
			this.isInsideInSufficient(date) ||
			this.isHoveredInSufficient(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

  protected get loadingUrl(){
    return `${environment.serverUrl}/check-in/by-employee`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/check-in/by-employee/export`;
  }

  protected get exportFileName(){
    return `Check-Ins Report`;
  }

  convertNgbDateToMoment(ngbDate: NgbDate): moment.Moment {
    if (!ngbDate) {
      throw new Error('Invalid NgbDate provided.');
    }
  
    // NgbDate has properties year, month, and day
    return moment({ year: ngbDate.year, month: ngbDate.month - 1, day: ngbDate.day });
  }

}
