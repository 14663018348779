<!--div div class="container-fluid">
  <div class="page-header" style="padding-bottom: 20px;">
    <div class="row">
      <div class="col-lg-4">
        <div class="page-header-left">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="'/dashboard'">
                <app-feather-icons [icon]="'home'"></app-feather-icons>
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">
              <a [routerLink]="breadcrumbs?.parentLink">
                {{breadcrumbs?.parentBreadcrumb}}
              </a>
            </li>
            <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
            </li>
          </ol>
        </div>
      </div>
      <div class="col-lg-4">
        <h3 class="underline-small" style="text-align:center">{{title}}</h3>
      </div>
      <div class="col-lg-4">
        <app-bookmark></app-bookmark>
      </div>
    </div>
  </div>
</div-->

<div class="page-header" style="padding-bottom: 20px;">
  <div class="row">
    <div class="col-lg-4">
      <div class="page-header-left">
        <p-breadcrumb [model]="menuItems" [home]="home"></p-breadcrumb>
      </div>
    </div>
    <div class="col-lg-4">
      <h3 class="underline-small" style="text-align:center">{{title}}</h3>
    </div>
    <div class="col-lg-4">
      
    </div>
  </div>
</div>
