import { state } from '@angular/animations';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, lastValueFrom, map } from 'rxjs';
import Helper from 'src/app/helper/Helper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { IS_UPLOAD_REQUEST} from 'src/app/types/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth-service.service';
import { MenuItem } from 'primeng/api';
import { SplitButton } from 'primeng/splitbutton';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { AppService } from 'src/app/app.service';
declare var require
const Swal = require('sweetalert2')


@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent implements OnInit {

  loading = false
  employees:[] = []
  selectedEmployee = 0
  selectedEmployeeForDistributor = 0

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private toastrService: ToastrService,
    private authService: AuthService, protected modalService: NgbModal, protected appService: AppService) { }

  ngOnInit() {
    this.appService.setTitle("UTILITIES")
    this.appService.setMenuItems([{label:'SETTINGS',disabled:true,icon:"pi pi-cog"}, {label:'UTILITIES',disabled:true,icon:"pi pi-wrench"}])

    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/employee`, { }),
     ]).pipe(
      map(([a]) => { 
        this.employees = a.data
      })
    ))
  }

  downloadDealerStock(){
    let params = new HttpParams()
    if( this.selectedEmployee && this.selectedEmployee != 0){
      params = params.set('employeeId', `${this.selectedEmployee}`);
    }

    params = params.set('type', `XLS`)
    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/vnd.ms-excel'}),
      params: params,
      responseType: 'blob'
    };
    this.loading = true
    this.httpClient.get<Blob>(`${environment.serverUrl}/dealer/stock/export`, httpOptions).subscribe((blob) => {
      this.loading = false
      saveAs(new Blob([blob], { type: 'vnd.ms-excel;charset=utf-8' }), `dealer-stock.xlsx`)
    },
    (error) => {
      this.loading = false
      console.error('Error during file download:', error);
    });
  }

  downloadDistributorStock(){
    let params = new HttpParams()
    if( this.selectedEmployeeForDistributor && this.selectedEmployeeForDistributor != 0){
      params = params.set('employeeId', `${this.selectedEmployeeForDistributor}`);
    }

    params = params.set('type', `XLS`)
    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/vnd.ms-excel'}),
      params: params,
      responseType: 'blob'
    };
    this.loading = true
    this.httpClient.get<Blob>(`${environment.serverUrl}/distributor/stock/export`, httpOptions).subscribe((blob) => {
      this.loading = false
      saveAs(new Blob([blob], { type: 'vnd.ms-excel;charset=utf-8' }), `distributor-stock.xlsx`)
    },
    (error) => {
      this.loading = false
      console.error('Error during file download:', error);
    });
  }

}
