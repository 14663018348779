import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coupon-payment-details',
  templateUrl: './coupon-payment-details.component.html',
  styleUrls: ['./coupon-payment-details.component.scss']
})
export class CouponPaymentDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
