import { lastValueFrom, combineLatest, of, map } from 'rxjs';
import { City, Distributor, District } from './../../../types/types';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from 'src/app/helper/Helper';

@Component({
  selector: 'app-search-and-add-distributor',
  templateUrl: './search-and-add-distributor.component.html',
  styleUrls: ['./search-and-add-distributor.component.scss']
})
export class SearchAndAddDistributorComponent implements OnInit {

  @Output() distributorSelected: EventEmitter<Distributor[]> = new EventEmitter();
  
  distributors : Distributor[] = []

  cities : City[] = []

  districts : District[] = []

  loading = false

  selectedDistributors : Distributor[] = []

  constructor(protected httpClient: HttpClient,public activeModal: NgbActiveModal) {
    
  }

  ngOnInit() {

    this.loading = true
    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/distributor`, { }),
      this.httpClient.get<[]>(`${environment.serverUrl}/helper/cities`, { }),
      this.httpClient.get<[]>(`${environment.serverUrl}/helper/districts`, { })
     ]).pipe(
      map(([a,b,c]) => { 
        this.distributors = a.data
        this.cities = b
        this.districts = c
        this.loading = false
      })
    ))
  }

  addSelecteddDistributor(){
    
    if( this.selectedDistributors.length == 0 ){
      Helper.showMessageAlert(`Error!`,"You haven't selected any distributors",`error`)
      return
    }

    Helper.showConfirmationAlert(`Are you sure?`,`You want to add ${this.selectedDistributors.length} distributors?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.distributorSelected.emit(this.selectedDistributors)
        this.activeModal.dismiss('Closed')
      }
    })
  }


}
