import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu } from '../../services/nav-service.service';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import {Router, RouterModule} from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class AdminMenuModule { 

  constructor(private router: Router){}
  
  public static MENUITEMS: Menu[] = [
		  {
        title: 'Dashboard', icon: 'home', type: 'link', active: true,path: 'dashboard',
        route:{
          path: 'dashboard',
          data: {
            breadcrumb: {
              label: 'Dashboard',
            },
            role: 'Admin',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'All Redeem Requests', icon: 'file-plus', type: 'link', active: false, path: 'redeem-requests',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"All Redeem Requests",
                breadcrumb: {
                  label: "All Redeem Requests",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            },
            {
              data: {
                title:"Enquiry Details",
                breadcrumb: {
                  label: "Enquiry Details",
                  info: 'icofont icofont-files',
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Coupon Payments', icon: 'file-plus', type: 'link', active: false, path: 'coupon-payments',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"All Coupon Payments",
                breadcrumb: {
                  label: "All Coupon Payments",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            },
            {
              data: {
                title:"Enquiry Details",
                breadcrumb: {
                  label: "Enquiry Details",
                  info: 'icofont icofont-files',
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Invoices', icon: 'file-plus', type: 'link', active: false, path: 'invoices',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"All Invoices",
                breadcrumb: {
                  label: "All Invoices",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            },
          ]
        }
      },
      {
        title: 'All Returns', icon: 'file-plus', type: 'link', active: false, path: 'product-returns',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"All Returns",
                breadcrumb: {
                  label: "All Invoices",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            },
          ]
        }
      },
      {
        title: 'All QRCode Batches', icon: 'file-plus', type: 'link', active: false, path: 'qrcode-batches',
        route:{
          children: [
            {
              data: {
                title:"All QRCode Batches",
                breadcrumb: {
                  label: "All QRCode Batches",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Products', icon: 'file-plus', type: 'link', active: false, path: 'products',
        route:{
          path: 'products',
          canActivate: [FlytAuthGuard],
          children: [
            {
              data: {
                title:"All Products",
                breadcrumb: {
                  label: "All Products",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Employees', icon: 'file-plus', type: 'link', active: false, path: 'employees',
        route:{
          children: [
            {
              data: {
                title:"All Employees",
                breadcrumb: {
                  label: "All Employees",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Contractors', icon: 'file-plus', type: 'link', active: false, path: 'contractors',
        route:{
          children: [
            {
              data: {
                title:"All Contractors",
                breadcrumb: {
                  label: "All Contractorss",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Distributors', icon: 'file-plus', type: 'link', active: false, path: 'distributors',
        route:{
          children: [
            {
              data: {
                title:"All Distributors",
                breadcrumb: {
                  label: "All Distributors",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Dealers', icon: 'file-plus', type: 'link', active: false, path: 'dealers',
        route:{
          children: [
            {
              data: {
                title:"All Dealers",
                breadcrumb: {
                  label: "All Dealers",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Projects', icon: 'file-plus', type: 'link', active: false, path: 'projects',
        route:{
          children: [
            {
              data: {
                title:"All Projects",
                breadcrumb: {
                  label: "All Projects",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Cities', icon: 'file-plus', type: 'link', active: false, path: 'cities',
        route:{
          children: [
            {
              data: {
                title:"All Cities",
                breadcrumb: {
                  label: "All Cities",
                  info: 'icofont icofont-files'
                },
                role: 'Admin',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      
      {
        title: 'Reports', icon: 'file-plus', type: 'sub', active: false,
        children:[
          {
            title: 'All Check-ins', icon: 'file-plus', type: 'link', active: false, path: 'reports/all-checkins',
            route:{
              children: [
                {
                  data: {
                    title:"All Dealers",
                    breadcrumb: {
                      label: "All Dealers",
                      info: 'icofont icofont-files'
                    },
                    role: 'Admin',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'Utilities', icon: 'file-plus', type: 'link', active: false, path: 'reports/utilities',
            route:{
              children: [
                {
                  data: {
                    title:"Utilities",
                    breadcrumb: {
                      label: "All Dealers",
                      info: 'icofont icofont-files'
                    },
                    role: 'Admin',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
        ]
      },

	]
}
