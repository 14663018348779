<div class="row">
  <div class="col-md-12 col-xl-12">
      <div class="card">
          <div class="card-header b-l-primary border-3">
              <button style="float: right;" type="button" class="btn btn-primary m-r-25" (click)="addNewCity();$event.preventDefault()"><i class="p-r-5 fa fa-plus"></i><span><strong>Add New City</strong></span></button>
          </div>
          <div class="card-body">
              <div #tableWrapper style="padding-left:15px;padding-right:15px;">
                  <ngx-datatable
                      #DatatableComponent
                      class="ngx-datatable content-table bootstrap fixed-header virtualized"
                      [loadingIndicator]="loading"
                      [columnMode]="'force'"
                      [headerHeight]="bShowFilters ? 105 : 50"
                      [footerHeight]="50"
                      [rowHeight]="65"
                      [externalPaging]="true"
                      [externalSorting]="true"
                      [count]="page.count"
                      [offset]="page.offset"
                      [limit]="page.limit"
                      [sortType]="'single'"
                      [reorderable]="true"
                      (page)="pageCallback($event)"
                      (sort)="sortCallback($event)"
                      [sorts]="[{prop: 'id', dir: 'desc'}]"
                      (activate)="onActivate($event)"
                      [scrollbarH]="true"
                      [rows]="items"
                      >
                      <ngx-datatable-column prop="id" name="ID" [width]="10" [resizeable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.id}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="name" name="Name" [width]="30" [resizeable]="true">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.name}}</strong>
                          </ng-template>
                          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                              <span class="datatable-header-cell-wrapper">
                                <span class="datatable-header-cell-label" (click)="sort()">{{'Name' | translate
                                  }}</span>
                              </span>
                              <div class="filter-background w-100">
                                <input [ngClass]="{'d-none':!bShowFilters}" (change)="name = $event.target.value"
                                [value]="name" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="name=$event.target.value;applyFilterAfterDelay()">
                              </div>
                            </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="district" name="District" [width]="30" [resizeable]="true">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.district?.name}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="createdAt" name="Created" [width]="30" [resizeable]="true">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.createdAt}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="updatedAt" name="Last Updated" [width]="30" [resizeable]="true">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.updatedAt}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                  
                      <ngx-datatable-footer>
                          <ng-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
                            let-curPage="curPage" let-offset="offset" ngx-datatable-footer-template>
                            <div class="controls">
                              <div>
                                <label class="datatable-body-cell-label " for="per-page">
                                  {{'Items Per Page' | translate}}
                                </label>
                                <select id="items-per-page" class="form-control items-per-page"
                                  (change)="onLimitChange($event.target.value)" [value]="page.limit"
                                  style="display:inline-block;width: 50px;text-align: center;">
                                  <option *ngFor="let option of pageLimitOptions" [value]="option.value">
                                    {{ option.value }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <app-datatable-pager (download)="exportAs($event)" [page]="curPage" [visiblePagesCount]="3"
                              [size]="pageSize" [count]="rowCount" [hidden]="false" (showFilter)="showFilters()"
                              (clearFilter)="clearFilter()" [filtersShowing]="bShowFilters" [filterApplied]="bFilterApplied"
                              (change)="onPageChange($event)">
                            </app-datatable-pager>
                          </ng-template>
                        </ngx-datatable-footer>
                  </ngx-datatable>
              </div>
          </div>
      </div>
  </div>
</div>

<p-dialog 
  autoZIndex="false"
  baseZIndex="1000"
  [(visible)]="viewDialog" 
  [style]="{ width: '600px' }" 
  [header]="'Payout Event Details'" 
  (onHide)="viewDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
  
  <ng-template pTemplate="content">
    <form [formGroup]="form">
      <div class="view-transaction">
        <div class="vt-container">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="donationType">City name</label><span class="text-danger">*</span>
              <input #formField pInputText id="name" type="text" class="form-control" formControlName="name" name="name" />
              <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                <div *ngIf="form.controls.name.errors.required">
                  City Name is required
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="donationType">District</label><span class="text-danger">*</span>
              
              <select class="form-control" class="form-control" formControlName="districtId" name="districtId">
                <option value="0">Select District</option>
                <option *ngFor="let district of districts" value="{{district.id}}">{{district.name}}</option>
              </select>

              <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                <div *ngIf="form.controls.name.errors.required">
                  District Name is required
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton style="background-color: darkred;" (click)="saveCity()" class="btn btn-primary m-r-5">SAVE</button>
  </ng-template>
</p-dialog>