import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Dealer, Distributor } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import Helper from '../../../helper/Helper';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-add-distributor',
  templateUrl: './add-distributor.component.html',
  styleUrls: ['./add-distributor.component.scss']
})
export class AddDistributorComponent implements OnInit {

  distributor: Distributor = undefined;

  @Output() distributorEntry: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  allDealers : []
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, public activeModal: NgbActiveModal,
    private toastrService: ToastrService) {
      
  }

  ngOnInit(): void {
    ["id", "name","mobileNumber","internalCode","active","createdAt","updatedAt"]
    if( this.distributor ){
      this.form = this.fb.group({
        id:this.distributor.id,
        name: [this.distributor.name],
        mobileNumber: [this.distributor.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [this.distributor.active],
        internalCode: [this.distributor.internalCode],
        role:['Distributor']
      });
    }
    else{
      this.form = this.fb.group({
        name: [''],
        mobileNumber: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [true],
        internalCode: [''],
        role:['Distributor']
      });
    }

  }

  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/distributor`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/distributor/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
      .subscribe({
        next: (v) => {
          console.log(v)
          this.activeModal.close('Success')
          if( this.form.value.id ){
            this.toastrService.success('Distributor details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Distributor added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.distributorEntry.emit(v);
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
