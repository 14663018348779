<div class="row">
  <div class="col-md-12 col-xl-12">
      <div class="card">
            <form  *ngIf="distributor !== undefined" (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="card-body p-b-0">
                  <div class="row">
                      <div class="col">
                          <div class="card shadow shadow-showcase">
                              <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                              <div class="card-body row p-b-0" style="margin-top:30px;">
                                  <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                      <label >Distributor Name</label>
                                      <span class="text-danger">*</span>
                                      <div class="input-group">
                                          <div class="input-group-prepend">
                                              <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                          </div>
                                          <input autofocus formControlName="name" type="text" class="form-control" placeholder="Name of the Distributor" required>
                                      </div>
                                      <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                                          <div *ngIf="form.controls.name.errors.required">
                                              Distributor Name  is required
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                      <label >Mobile Number </label>
                                      <span class="text-danger">*</span>
                                      <div class="input-group">
                                          <div class="input-group-prepend">
                                              <span class="input-group-text">+91</span>
                                          </div>
                                          <input autofocus formControlName="mobileNumber" type="text" class="form-control" placeholder="Format - XXXXXXXXXX" required maxlength=10 (keypress)="numberOnly($event)">
                                      </div>
                                      <div *ngIf="form.controls.mobileNumber.invalid && (form.controls.mobileNumber.dirty || form.controls.mobileNumber.touched)" class="text text-danger">
                                          <div *ngIf="form.controls.mobileNumber.errors.required">
                                              Mobile Number is required
                                          </div>
                                          <div *ngIf="form.controls.mobileNumber.errors.pattern || form.controls.mobileNumber.errors.maxlength || form.controls.mobileNumber.errors.minlength " class="text text-danger">
                                              Mobile number must be 10 numbers
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-6 col-xl-4 form-group m-form__group">
                                    <label>Address</label>
                                    <div class="input-group">
                                        <input autofocus formControlName="address" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-4 form-group m-form__group">
                                    <label>Latitude</label>
                                    <div class="input-group">
                                        <input autofocus formControlName="latitude" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-4 form-group m-form__group">
                                    <label>Longitude</label>
                                    <div class="input-group">
                                        <input autofocus formControlName="longitude" type="text" class="form-control">
                                    </div>
                                </div>
                                  <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                      <label >Active? </label>
                                      <span class="text-danger">*</span>
                                      <div class="input-group">
                                          <input type="checkbox" formControlName="active"/>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                    <button style="float: right;" type="submit" class="btn btn-primary me-1">Save Distributor Details</button>
                                    <button (click)="resetPassword()" style="float: right;" type="button" class="btn btn-primary me-1">Reset Passsword</button>
                                  </div>
                                </div>
                                <p-tabView>
                                    <p-tabPanel header="All Dealers">
                                        <p-table styleClass="p-datatable-gridlines" [value]="distributor.dealers" [paginator]="true" [rows]="10"
                                            [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            [rowsPerPageOptions]="[10,25,50]">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="width:15%">Name</th>
                                                    <th style="width:15%">Mobile</th>
                                                    <th style="width:15%">City</th>
                                                    <th style="width:15%">District</th>
                                                    <th style="width:10%">Verified</th>
                                                    <th style="width:5%">Actions</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-dealer>
                                                <tr>
                                                    <td>{{dealer.name}}</td>
                                                    <td>{{dealer.mobileNumber}}</td>
                                                    <td>{{dealer.city?.name}}</td>
                                                    <td>{{dealer.city?.district?.name}}</td>
                                                    <td>{{dealer.verified}}</td>
                                                    <td><p-button type="button" icon="pi pi-trash" styleClass="p-button-text"
                                                            (click)="deleteDealer(dealer);$event.preventDefault()"></p-button></td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="paginatorleft">
                                                <button style="float: left;" type="button" class="btn btn-primary me-1"
                                                    (click)="addDealer();$event.preventDefault()">Add dealer</button>
                                            </ng-template>
                                        </p-table>
                                    </p-tabPanel>
                                    <p-tabPanel header="Running Stock">
                                        <div class="card-body p-t-0 p-b-0">
                                            <div *ngIf="distributor !== undefined" class="card p-t-15">
                                                <p-table styleClass="p-datatable-gridlines" [value]="distributor.runningStock" [paginator]="true"
                                                    [rows]="25" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                                    [rowsPerPageOptions]="[10,25,50]">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th style="width:15%">Product</th>
                                                            <th style="width:15%;text-align: end;">Quantity</th>
                                                            <th style="width:15%;text-align: end;">Price</th>
                                                            <th style="width:15%;text-align: end;">Total Value</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-runningStock>
                                                        <tr>
                                                            <td>{{runningStock.product?.name}}</td>
                                                            <td style="text-align: end;">{{runningStock.stock | number:'1.0-0'}}</td>
                                                            <td style="text-align: end;">{{runningStock.product.price | currency:'INR':'symbol-narrow'
                                                                }}</td>
                                                            <td style="text-align: end;">{{ ( runningStock.stock * runningStock.product.price) |
                                                                currency:'INR':'symbol-narrow' }}</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </p-tabPanel>
                                </p-tabView>
                          </div>
                      </div>
                  </div>
              </div>
            </form>
      </div>
  </div>
</div>