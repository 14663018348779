import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';
import { ApiSharingService } from './services/api-sharing-service.service';
import { AuthService } from './services/auth-service.service';
import { UploadFilesService } from './services/upload-files.service';
import { LocalStorageService } from './services/local-storage.service';
import { FlytAuthGuard } from './guards/flyt-auth-guard.guard';
import { ConfirmationGuard } from './guards/confirmation-guard.guard';
import { CookieService } from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService} from './interceptor/auth-interceptor-service.service';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {NavigationStart, Router, RouterModule} from '@angular/router';
import * as jQuery from 'jquery';
import { ListBaseComponent } from './components/list-base/list-base.component';
import { ContractorModule } from './modules/contractor/contractor.module';
import { DistributorModule } from './modules/distributor/distributor.module';
import { DealerModule } from './modules/dealer/dealer.module';
import { EmployeeModule } from './modules/employee/employee.module';
import { ProductModule } from './modules/product/product.module';
import { InvoiceModule } from './modules/invoice/invoice.module';
import { CheckinModule } from './modules/checkin/checkin.module';
import { ProductReturnsModule } from './modules/product-returns/product-returns.module';
import { RedeemRequestModule } from './modules/redeem-requests/redeem-request.module';
import { CouponPaymentsModule } from './modules/coupon-payments/coupon-payments.module';
import { QRCodesModule } from './modules/qrcodes/qrcodes.module';
import { ProjectModule } from './modules/project/project.module';
import { filter } from 'rxjs';

window['$'] = jQuery;


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListBaseComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    RouterModule,
    BreadcrumbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    ContractorModule,
    DistributorModule,
    ProductReturnsModule,
    DealerModule,
    EmployeeModule,
    InvoiceModule,
    CheckinModule,
    RedeemRequestModule,
    CouponPaymentsModule,
    ProductModule,
    QRCodesModule,
    ProjectModule
  ],
  exports:[
    RouterModule
  ],
  providers: [AuthService, LocalStorageService, 
    FlytAuthGuard, CookieService, DecimalPipe,
    ConfirmationGuard,
    ApiSharingService,UploadFilesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
     },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  constructor(private readonly router: Router,) {
    this.router.events
    .pipe(
      // You can also use traditional if else in the subscribe 
      filter(event => event instanceof NavigationStart)
    )
    .subscribe(event => {
      
    });
 
  }
  
}
