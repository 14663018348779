<div class="row">
  <div class="col-md-12 col-xl-12">
      <div class="card">
            <form  *ngIf="dealer !== undefined" (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="card-body p-b-0">
                  <div class="row">
                      <div class="col">
                          <div class="card shadow shadow-showcase">
                              <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                              <div class="card-header b-l-primary border-3">
                                <button style="float: right;" type="button" class="btn btn-primary m-r-25" (click)="exportPendingAndVerified();$event.preventDefault()"><i class="p-r-5 fa fa-plus"></i><span><strong>Export Verified Pending Txns</strong></span></button>
                                <input #attachments id="bankExcelSheet" type="file" (change)="uploadExcelSheet($event)" style="display: none;" accept=".xlsx, .xls, .csv"/>
                            </div>
                              <div class="card-body row p-b-0" style="margin-top:30px;">
                                  <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                      <label>Dealer Name</label>
                                      <span class="text-danger">*</span>
                                      <div class="input-group">
                                          <div class="input-group-prepend">
                                              <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                          </div>
                                          <input autofocus formControlName="name" type="text" class="form-control" placeholder="Name of the Dealer" required>
                                      </div>
                                      <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                                          <div *ngIf="form.controls.name.errors.required">
                                              Dealer Name  is required
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                      <label>Mobile Number </label>
                                      <span class="text-danger">*</span>
                                      <div class="input-group">
                                          <div class="input-group-prepend">
                                              <span class="input-group-text">+91</span>
                                          </div>
                                          <input autofocus formControlName="mobileNumber" type="text" class="form-control" placeholder="Format - XXXXXXXXXX" required maxlength=10 (keypress)="numberOnly($event)">
                                      </div>
                                      <div *ngIf="form.controls.mobileNumber.invalid && (form.controls.mobileNumber.dirty || form.controls.mobileNumber.touched)" class="text text-danger">
                                          <div *ngIf="form.controls.mobileNumber.errors.required">
                                              Mobile Number is required
                                          </div>
                                          <div *ngIf="form.controls.mobileNumber.errors.pattern || form.controls.mobileNumber.errors.maxlength || form.controls.mobileNumber.errors.minlength " class="text text-danger">
                                              Mobile number must be 10 numbers
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                    <label>Bank Account Number </label>
                                    <span class="text-danger">*</span>
                                    <div class="input-group">
                                        <input autofocus formControlName="bankAccountNumber" type="text" class="form-control" placeholder="Format - XXXXXXXXXX" required>
                                    </div>
                                    <div *ngIf="form.controls.bankAccountNumber.invalid && (form.controls.bankAccountNumber.dirty || form.controls.bankAccountNumber.touched)" class="text text-danger">
                                        <div *ngIf="form.controls.bankAccountNumber.errors.required">
                                            Bank Account Number is required
                                        </div>
                                        <div *ngIf="form.controls.bankAccountNumber.errors.maxlength || form.controls.bankAccountNumber.errors.minlength " class="text text-danger">
                                            Bank Account Number must be between 5 and 25 characters
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                    <label>IFSC Code </label>
                                    <span class="text-danger">*</span>
                                    <div class="input-group">
                                        <input autofocus formControlName="ifscCode" type="text" class="form-control" placeholder="Format - XXXXXXXXXX" required>
                                    </div>
                                    <div *ngIf="form.controls.ifscCode.invalid && (form.controls.ifscCode.dirty || form.controls.ifscCode.touched)" class="text text-danger">
                                        <div *ngIf="form.controls.ifscCode.errors.required">
                                            IFSC Code is required
                                        </div>
                                        <div *ngIf="form.controls.ifscCode.errors.maxlength || form.controls.ifscCode.errors.minlength " class="text text-danger">
                                            IFSC Code must be between 5 and 20 characters
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                    <label>UPI ID </label>
                                    <div class="input-group">
                                        <input autofocus formControlName="upiId" type="text" class="form-control" placeholder="Format - XX@XXXXX">
                                    </div>
                                    <div *ngIf="form.controls.upiId.invalid && (form.controls.upiId.dirty || form.controls.upiId.touched)" class="text text-danger">
                                        <div *ngIf="form.controls.upiId.errors.pattern" class="text text-danger">
                                            Invalid UPI ID
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-6 form-group m-form__group"></div>
                                <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                    <label>City</label>
                                    <div class="input-group">
                                        <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="city" [suggestions]="filteredCities" (onSelect)="onCitySelected($event)" (completeMethod)="filterCity($event)" field="name" [dropdown]="true">
                                        <ng-template let-city pTemplate="item">
                                            <div class="city-item">
                                            <div>{{city.name}}</div>
                                            </div>
                                        </ng-template>
                                        </p-autoComplete>
                                    </div>
                                    <div *ngIf="form.controls.city.invalid && (form.controls.city.dirty || form.controls.city.touched)" class="text text-danger">
                                        <div *ngIf="form.controls.city.errors.pattern" class="text text-danger">
                                            City is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                    <label>District</label>
                                    <div class="input-group">
                                        <input readonly formControlName="district" type="text" class="form-control">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-xl-4 form-group m-form__group">
                                    <label>Address</label>
                                    <div class="input-group">
                                        <input autofocus formControlName="address" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-4 form-group m-form__group">
                                    <label>Latitude</label>
                                    <div class="input-group">
                                        <input autofocus formControlName="latitude" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-4 form-group m-form__group">
                                    <label>Longitude</label>
                                    <div class="input-group">
                                        <input autofocus formControlName="longitude" type="text" class="form-control">
                                    </div>
                                </div>

                                  <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                      <label>Verified? </label>
                                      <span class="text-danger">*</span>
                                      <div class="input-group">
                                          <input type="checkbox" formControlName="verified"/>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                    <button style="float: right;" type="submit" class="btn btn-primary me-1">Save Dealer Details</button>
                                  </div>
                                </div>
                                <p-tabView>
                                    <p-tabPanel header="All Distributors">
                                        <div class="card-body p-t-0 p-b-0">
                                        <div *ngIf="dealer !== undefined" class="card p-t-15">
                                            <h5>Distributors</h5>
                                            
                                            <p-table styleClass="p-datatable-gridlines" [value]="dealer.distributors" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="width:15%">Name</th>
                                                        <th style="width:15%">Mobile</th>
                                                        <th style="width:10%">Active</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-distributor>
                                                    <tr>
                                                        <td>{{distributor.name}}</td>
                                                        <td>{{distributor.mobileNumber}}</td>
                                                        <td>{{distributor.active}}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel header="All Sales Officers">
                                        <div class="card-body p-t-0 p-b-0">
                                            <div *ngIf="dealer !== undefined" class="card p-t-15">
                                              <h5>Sales Officers</h5>
                                              
                                              <p-table styleClass="p-datatable-gridlines" [value]="dealer.employees" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                                                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
                                                  <ng-template pTemplate="header">
                                                      <tr>
                                                          <th style="width:15%">Name</th>
                                                          <th style="width:15%">Mobile</th>
                                                          <th style="width:10%">Active</th>
                                                      </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="body" let-distributor>
                                                      <tr>
                                                          <td>{{distributor.name}}</td>
                                                          <td>{{distributor.mobileNumber}}</td>
                                                          <td>{{distributor.active}}</td>
                                                      </tr>
                                                  </ng-template>
                                              </p-table>
                                          </div>
                                        </div>
                                    </p-tabPanel>
                                </p-tabView>
                          </div>
                      </div>
                  </div>
              </div>
            </form>
      </div>
  </div>
</div>