import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { delay, lastValueFrom, Subject, Subscription } from 'rxjs';
import { Dealer, Distributor, Employee } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import Helper from 'src/app/helper/Helper';
import { AppService } from 'src/app/app.service';
import { SearchAndAddDealerComponent } from 'src/app/shared/components/search-and-add-dealer/search-and-add-dealer.component';
import { SearchAndAddDistributorComponent } from 'src/app/shared/components/search-and-add-distributor/search-and-add-distributor.component';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent implements OnInit {

  employee?: Employee = undefined;

  @Output() employeeEntry: EventEmitter<any> = new EventEmitter();

  dealerAddedObserver: Subscription;

  distributorAddedObserver: Subscription;

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, 
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, 
    private toastrService: ToastrService, private appService: AppService) {
      
     }

  ngOnInit(): void {
    
    this.loadEmployeeData()
  }

  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/employee`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/employee/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
      .subscribe({
        next: (v) => {
          console.log(v)
          if( this.form.value.id ){
            this.toastrService.success('Employee details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Employee added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.employeeEntry.emit(v);
          this.reloadComponent(true)
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
    //skipLocationChange:true means dont update the url to / when navigating
   console.log("Current route I am on:",this.router.url);
   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       console.log(`After navigation I am on:${this.router.url}`)
     })
   })
 }

  loadEmployeeData(){
    lastValueFrom(this.http.get(`${environment.serverUrl}/employee/${this.route.snapshot.params.id}`)).then( (result:any) => {  
      this.form = this.fb.group({
        id:result.id,
        name: [result.name],
        mobileNumber: [result.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [result.active],
        role: [result.user.role, [Validators.required,this.notAllowed(/^0/)]],
      });
      this.employee = result
      this.appService.setTitle(this.employee.name)
      this.appService.setMenuItems([{label:'All Employees', routerLink: '/employees',iconClass:"pi pi-users"},{label:this.employee.name,disabled:true}])
    })
    .catch(e => {
      const message = e.error?.errors?.length > 0 ?  e.error?.errors[0] : e.error?.message
      if( message)
        Helper.showMessageAlert(`Error!`,message,`error`)
    });
  }

  addDealer(){
    const modalRef = this.modalService.open(SearchAndAddDealerComponent,{ centered: true,size: 'xl'});
    modalRef.componentInstance.name = 'Add / Dealer';
    
    if( this.dealerAddedObserver ){
      this.dealerAddedObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/

    this.dealerAddedObserver = modalRef.componentInstance.dealerSelected.subscribe((dealers: Dealer[]) => {
      const dealerIds = []
      dealers.map( (d) =>{
        dealerIds.push(d.id)
      })
      
      this.http.post(`${environment.serverUrl}/employee/${this.employee.id}/add-dealers`, JSON.stringify({"dealers":dealerIds}))
      .subscribe({
        next: (v) => {
          this.toastrService.success('Dealer added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          this.loadEmployeeData()
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
    });
  }

  resetPassword(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want reset the password for employee ${this.employee.name}?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.http.post(`${environment.serverUrl}/employee/reset-password/${this.employee.id}`,{}).subscribe({
          next: () => {
            this.toastrService.success('Employee password updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          },
          error: (e) => {
            Helper.processError(e)
          }
        }); 
      }
    })
  }

  deleteDealer(selectedDealer){
    Helper.showConfirmationAlert(`Are you sure?`,`You want remove selected dealer from the sales officer?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.http.delete(`${environment.serverUrl}/employee/${this.employee.id}/remove-dealer/${selectedDealer.id}`).subscribe({
          next: () => {
            this.employee.dealers.forEach( (dealer, index, dealers) => {
              if(dealer.id === selectedDealer.id){
                this.employee.dealers.splice(index,1);
              }
            });
          },
          error: (e) => {
            Helper.processError(e)
          }
        }); 
      }
    })
  }

  addDistributor(){
    const modalRef = this.modalService.open(SearchAndAddDistributorComponent,{ centered: true,size: 'xl'});
    modalRef.componentInstance.name = 'Add / Distributor';
    
    if( this.distributorAddedObserver ){
      this.distributorAddedObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/

    this.distributorAddedObserver = modalRef.componentInstance.distributorSelected.subscribe((distributors: Distributor[]) => {
      const distributorIds = []
      distributors.map( (d) =>{
        distributorIds.push(d.id)
      })
      
      this.http.post(`${environment.serverUrl}/employee/${this.employee.id}/add-distributors`, JSON.stringify({"distributors":distributorIds}))
      .subscribe({
        next: (v) => {
          this.toastrService.success('Distributor added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          this.loadEmployeeData()
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
    });
  }

  deleteDistributor(selectedDistributor){
    Helper.showConfirmationAlert(`Are you sure?`,`You want remove selected distributor from the sales officer?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.http.delete(`${environment.serverUrl}/employee/${this.employee.id}/remove-distributor/${selectedDistributor.id}`).subscribe({
          next: () => {
            this.employee.distributors.forEach( (distributor, index, distributors) => {
              if(distributor.id === selectedDistributor.id){
                this.employee.distributors.splice(index,1);
              }
            });
          },
          error: (e) => {
            Helper.processError(e)
          }
        }); 
      }
    })
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  notAllowed(input: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = input.test(control.value);
      return forbidden ? {notAllowed: {value: control.value}} : null;
    };
  }
}
