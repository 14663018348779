import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { Dealer } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../app.service';
import { combineLatest, lastValueFrom, map, Subject } from 'rxjs';
import { ColumnMode, DatatableComponent, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Helper from 'src/app/helper/Helper';


@Component({
  selector: 'app-list-all-cities',
  templateUrl: './list-all-cities.component.html',
  styleUrls: ['./list-all-cities.component.scss']
})
export class ListAllCitiesComponent extends ListBaseComponent<Dealer> implements OnInit {

  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('DatatableComponent') ngxDatatable: DatatableComponent;

  private currentComponentWidth

  bShowFilters = false

  viewDialog = false

  name:string = '';

  districts: [] = []

  form: FormGroup = undefined; // Define the form group

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder) {
      super(http,breadcrumbService,appService,offcanvasService,router,route, ); 
  }

  ngOnInit() {

    lastValueFrom(combineLatest([
      this.http.get<any>(`${environment.serverUrl}/helper/districts`, { }),
     ]).pipe(
      map(([a]) => { 
        this.districts = a.data
      })
    ))
    
    this.appService.setTitle("All Dealers")
    this.appService.setMenuItems([{label:'All Dealers',disabled:true,routerLink: '/dealers',iconClass:"pi pi-users"}])

    this.pageCallback({ offset: 0 });
  }

  ngAfterViewChecked() {
    // Check if the table size has changed,
    if (this.ngxDatatable && this.ngxDatatable.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.ngxDatatable.columnMode = ColumnMode.force;
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.ngxDatatable.recalculate();
      
    }
  }


  showFilters(){
    this.bShowFilters = !this.bShowFilters
    if( !this.bShowFilters){
      this.clearFilter();
    }
    setTimeout(() => {
      this.ngxDatatable.recalculate()
    }, 100);
  }
  clearFilter(){
    
    this.name = ""
    super.clearFilter();
  }

  applyFilterAfterDelay(){
    setTimeout(() => {
      this.applyFilter();
    }, 10); 
  }

  populateParamsForQuery(params: HttpParams): HttpParams{

    if( this.name.length > 0){
      params = params.set('name', `${this.name}`);
    }
    return params;
  }

  onActivate(event){
    if(event.type == 'click') {
      console.log(event.row);
      this.editCity(event.row)
    }
  }

  addNewCity(){
    
    this.form = this.fb.group({
      name: ['',Validators.required ],
      districtId: ['',Validators.required ],
      id: [''],
    });
    this.viewDialog = true
    
  }

  editCity(data){
    this.form = this.fb.group({
      name: [data.name,Validators.required ],
      districtId: [data.district?.id,Validators.required ],
      id: [data.id],
    });
    this.viewDialog = true
  }

  saveCity(){

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      Helper.showMessageAlert('Error',`Please fill in the name before saving.`,'error');
      return;
    }

    this.http.post(`${environment.serverUrl}/city/`, this.form.value).subscribe({
        next: (v:any) => {
          console.log(v)
          Helper.showMessageAlert('Success','City saved successfully!','success');
          this.viewDialog = false
          this.reloadTable()
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e);
        },
        complete: () => console.info('complete')
      });

  }

  protected get loadingUrl(){
    return `${environment.serverUrl}/city/`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/dealer/export`;
  }

  protected get exportFileName(){
    return `Dealer Report`;
  }

}
