import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table'
import { ToolbarModule } from 'primeng/toolbar'
import { ButtonModule } from 'primeng/button'
import { DropdownModule } from 'primeng/dropdown'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { TabViewModule } from 'primeng/tabview'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from 'primeng/inputnumber';
import { ListReturnInvoiceComponent } from './list-product-returns/list-product-returns.component';
import { AddReturnInvoiceComponent } from './add-product-returns/add-product-returns.component';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    TableModule,
    ProgressSpinnerModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    TabViewModule,
    InputNumberModule,
    ToolbarModule,
    AutoCompleteModule,
    ButtonModule
  ],
  declarations: [
    AddReturnInvoiceComponent,
    ListReturnInvoiceComponent
  ]
})
export class ProductReturnsModule { }
