import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListDistributorComponent } from './list-distributor/list-distributor.component';
import { AddDistributorComponent } from './add-distributor/add-distributor.component';
import { DistributorDetailComponent } from './distributor-detail/distributor-detail.component';
import { TableModule } from 'primeng/table'
import { ToolbarModule } from 'primeng/toolbar'
import { ButtonModule } from 'primeng/button'
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    TableModule,
    ProgressSpinnerModule,
    ToolbarModule,
    ButtonModule
  ],
  declarations: [ListDistributorComponent, AddDistributorComponent, DistributorDetailComponent]
})
export class DistributorModule { }
