import { Distributor } from './../types/types';
import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { DefaultComponent } from '../modules/dashboard/default/default.component';
import { FlytAuthGuard } from '../guards/flyt-auth-guard.guard';
import { TeamMemberMenuModule } from '../menu/team-member-menu/team-member-menu.module'
import { AdminMenuModule } from '../menu/admin-menu/admin-menu.module'
import { Route } from '@angular/router';
import { AuthService } from './auth-service.service';
import { User } from '../types/types';
import DistributorMenu from 'src/app/menu/DistributorMenu'

/*export interface Route {
  path?:string;
  data?:any;
  outlet?:string;
  animation?:any;
  component?:any;
  children?: any[];
  canActivate?: any;
  canDeactivate?: any;
}*/
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
  	route?:Route;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	public menu: {[key: string]: Menu[]} = {};
	public items: BehaviorSubject<Menu[]>;
	
	constructor(private authService: AuthService) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		this.menu['Admin'] = AdminMenuModule.MENUITEMS;
		this.menu['Distributor'] = DistributorMenu.getMenu();
		this.menu['TEAM_MEMBER'] = TeamMemberMenuModule.MENUITEMS;
		
		this.authService.getCurrentUser().subscribe({
			next:((user:User) => {
				const menu = this.menu[user?.role];
				this.items = new BehaviorSubject<Menu[]>(menu);
			}),
			error: (err) => {
			  //Handled in Auth service
			},
		  });
		
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
}
