import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, lastValueFrom, map, Subject } from 'rxjs';
import { Dealer, Distributor, Invoice, InvoiceTransactionItem, ReturnInvoice, User } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import Helper from '../../../helper/Helper';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../app.service';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { AuthService } from '../../../services/auth-service.service';


@Component({
  selector: 'app-add-product-returns',
  templateUrl: './add-product-returns.component.html',
  styleUrls: ['./add-product-returns.component.scss']
})
export class AddReturnInvoiceComponent implements OnInit {

  @ViewChild('invoiceTable') invoiceTable: any;

  invoiceId: number = undefined;

  invoicedByCompany = true

  invoice: ReturnInvoice = undefined;

  @Output() invoiceEntry: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  invoiceItem : any

  invoiceItems : InvoiceTransactionItem[] = []

  selectedInvoiceItems: InvoiceTransactionItem[] = [];

  products :any[] = [{name: 'Product 1', id:1},{name: 'Product 2', id:2},{name: 'Product 3', id:3}]

  submitted: boolean;
  itemDialog: boolean;

  filteredDealers : Dealer[] = []
  filteredDistributors : Distributor[] = []

  today =  new Date().toISOString().split('T')[0]

  loggedInUser : User = undefined

  editEnabled = false

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, public activeModal: NgbActiveModal,
    private toastrService: ToastrService, private authService: AuthService) {
      
      this.authService.getCurrentUser().subscribe({
        next:((user) => {
          this.loggedInUser = user
          if( this.loggedInUser.userType === 'Distributor') this.invoicedByCompany = false
        })
      })

      lastValueFrom(combineLatest([
        this.http.get<any>(`${environment.serverUrl}/product/all`, { }),
       ]).pipe(
        map(([a]) => { 
          this.products = a.data
        })
      ))
  }


  ngOnInit(): void {

    if( this.invoiceId ){
      this.loadInvoiceData()
    }
    else{
      this.form = this.fb.group({
        invoiceDate: ['', [Validators.required]],
        returnInvoiceType:[!this.invoicedByCompany ? 'DealerToDistributor': ''],
        returnInvoicedByDealer: undefined,
        returnInvoicedByDistributor: undefined
      });
    }
  }

  loadInvoiceData(){
    lastValueFrom(this.http.get(`${environment.serverUrl}/return-invoice/${this.invoiceId}`)).then( (result:any) => {  
      this.invoice = result
      this.invoiceItems = this.invoice.items
      this.form = this.fb.group({
        id:this.invoice.id,
        invoiceDate: [ formatDate(this.invoice.invoiceDate, 'yyyy-MM-dd', 'en'), [Validators.required]],
        returnInvoiceType:[!this.invoicedByCompany ? 'DealerToDistributor': ''],
        returnInvoicedByDealer: this.invoice.returnInvoicedByDealer,
        returnInvoicedByDistributor: this.invoice.returnInvoicedByDistributor,
        distributor: [this.invoice.distributor],
      });
      
    })
    .catch(e => {
      const message = e.error?.errors?.length > 0 ?  e.error?.errors[0] : e.error?.message
      if( message)
        Helper.showMessageAlert(`Error!`,message,`error`)
    });
  }

  addNew() {
    this.resetInvoiceItem()
    this.submitted = false;
    this.itemDialog = true;
  }

  editItem(item: any) {
    this.invoiceItem = {...item};
    this.itemDialog = true;
  }

  deleteItem(item: any) {
    Helper.showConfirmationAlert(`Are you sure?`,`You want to delete ${item.product.name}?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.invoiceItems = this.invoiceItems.filter(val => val.id !== item.id);
        this.resetInvoiceItem()
      }
    })
  }

  resetInvoiceItem(){
    this.invoiceItem = {
      id: undefined,
      product: {
        id: undefined,
        name:''
      },
      quantity: 0.0,
      price: 0.0
    } as InvoiceTransactionItem;
  }

  saveItem(){

    for (let i = 0; i < this.invoiceItems.length; i++) {
        if (this.invoiceItems[i].product.id === this.invoiceItem.product.id ) {
          if( this.invoiceItem.id && this.invoiceItems[i].id !== this.invoiceItem.id ){
            Helper.showMessageAlert(`Error!`,`Product ${this.invoiceItem.product.name} already exists in the items.`,`error`)
            return
          }
          else if( !this.invoiceItem.id ){
            Helper.showMessageAlert(`Error!`,`Product ${this.invoiceItem.product.name} already exists in the items.`,`error`)
            return
          }
        }
    }

    this.submitted = true;

    if (this.invoiceItem.product && this.invoiceItem.quantity > 0 ) {
        if (this.invoiceItem.id) {
            this.invoiceItems[this.findIndexById(this.invoiceItem.id)] = this.invoiceItem;                
        }
        else {
            this.invoiceItem.id = this.createId();
            this.invoiceItems.push(this.invoiceItem);
        }

        this.invoiceItems = [...this.invoiceItems];
        this.itemDialog = false;
        this.resetInvoiceItem()
    }
    else{

    }
  }

  editInvoice(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to edit this invoice?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.editEnabled = true
      }
    })
  }

  deleteSelectedItems() {

    Helper.showConfirmationAlert(`Are you sure?`,`You want to delete ${this.selectedInvoiceItems.length} item?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.invoiceItems = this.invoiceItems.filter(val => !this.selectedInvoiceItems.includes(val));
        this.selectedInvoiceItems = [];
      }
    })
  }

  onProductChanged(e){
    if( this.invoiceItem.product && this.invoiceItem.quantity ){
      this.invoiceItem.price = this.invoiceItem.quantity * this.invoiceItem.product.maximumDealerPrice
    }
  }

  quantityChanged(e){
    if( this.invoiceItem.product ){
      this.invoiceItem.price = e.value * this.invoiceItem.product.maximumDealerPrice
    }
  }

  hideDialog() {
    this.itemDialog = false;
    this.submitted = false;
  }
  saveInvoice(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if( this.invoicedByCompany ){
      if( !this.form.value.returnInvoiceType){
        Helper.showMessageAlert(`Error!`,"Please select Invoiced By",`error`)
        return
      }

      if( this.form.value.returnInvoiceType === 'DealerToCompany' && !this.form.value.returnInvoicedByDealer ){
        Helper.showMessageAlert(`Error!`,"You must select a dealer before you can save the invoice",`error`)
        return
      }
      else if( this.form.value.returnInvoiceType === 'DistributorToCompany' && !this.form.value.returnInvoicedByDistributor ){
        Helper.showMessageAlert(`Error!`,"You must select a distributor before you can save the invoice",`error`)
        return
      }
    }
    else if(!this.form.value.returnInvoicedByDealer){
      Helper.showMessageAlert(`Error!`,"You must select a dealer before you can save the invoice",`error`)
      return
    }

    if(this.invoiceItems.length == 0 ){
      Helper.showMessageAlert(`Error!`,"You don't have any items in the invoice",`error`)
      return
    }

    let postUrl = `${environment.serverUrl}/return-invoice`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/return-invoice/${this.form.value.id}`
    }

    const invoiceDetails:any = {}
    invoiceDetails.invoiceDate = this.form.value.invoiceDate
    if( this.invoicedByCompany ){
      invoiceDetails.returnInvoiceType = this.form.value.returnInvoiceType
    }
    else{
      invoiceDetails.returnInvoiceType = "DealerToDistributor"
    }
    invoiceDetails.returnInvoicedByDealer = this.form.value.returnInvoicedByDealer ? (({ id,name }) => ({ id,name }))(this.form.value.returnInvoicedByDealer) : undefined
    invoiceDetails.returnInvoicedByDistributor = this.form.value.returnInvoicedByDistributor ? (({ id,name }) => ({ id,name }))(this.form.value.returnInvoicedByDistributor) : undefined
    const items = this.invoiceItems.map((item) =>{
      console.log(typeof item.id)
      return {
        id: item.id && (typeof item.id !== "string") ? item.id : undefined,
        product: (({ id,name }) => ({ id,name }))(item.product),
        quantity : item.quantity,
        price : item.price
      }
    })
    invoiceDetails['items'] = items
    
    this.http.post(postUrl, JSON.stringify(invoiceDetails))
      .subscribe({
        next: (v) => {
          console.log(v)
          this.activeModal.close('Success')
          if( this.form.value.id ){
            this.toastrService.success('Invoice details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Invoice added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.invoiceEntry.emit(v);
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  filterDealers(event) {
    this.http.get<any>(`${environment.serverUrl}/dealer/search?keyword=${event.query}&take=15&skip=0`, { })
    .subscribe({
      next: (response: any) => {
        this.filteredDealers = response.data
      },
      error: (e) => {
        
      }
    });
  }

  filterDistributors(event) {
    this.http.get<any>(`${environment.serverUrl}/distributor/search?keyword=${event.query}&take=15&skip=0`, { })
    .subscribe({
      next: (response: any) => {
        this.filteredDistributors = response.data
      },
      error: (e) => {
      console.log(e)  
      }
    });
  }

  onDealerSelected(e){
    
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  invoiceDateChanged(){
    
  }

  findIndexById(id: string | number): number {
    let index = -1;
    for (let i = 0; i < this.products.length; i++) {
        if (this.invoiceItems[i].id === id) {
            index = i;
            break;
        }
    }

    return index;
  }
  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }

}
