import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AllCheckInReportComponent } from './cumulative-report/cumulative-report.component';
import { ListAllCitiesComponent } from './list-all-cities/list-all-cities.component';
import { UtilitiesComponent } from './utilities/utilities.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabViewModule } from 'primeng/tabview'
import { TableModule } from 'primeng/table'
import { DialogModule } from 'primeng/dialog'
import { ButtonModule } from 'primeng/button'
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    AllCheckInReportComponent,
    UtilitiesComponent,
    ListAllCitiesComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    CKEditorModule,
    NgbDatepickerModule,
    TabViewModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ProgressSpinnerModule,
    NgbModule
  ]
})
export class CheckinModule { }
