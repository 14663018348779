<div class="row">
  <div class="col-md-12 col-xl-12">
      <div class="card">
            <form  *ngIf="project !== undefined" (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="card-body p-b-0">
                  <div class="row">
                      <div class="col">
                          <div class="card shadow shadow-showcase">
                              <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                              <div class="card-header b-l-primary border-3">
                            </div>
                              <div class="card-body row p-b-0" style="margin-top:30px;">
                                <div class="col-sm-12 col-xl-3 row form-group m-form__group"></div>
                                <div class="col-sm-12 col-xl-6 row form-group m-form__group">
                                    <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                        <label>Project Name</label>
                                        <span class="text-danger">*</span>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="icofont icofont-building-alt"></i></span>
                                            </div>
                                            <input autofocus formControlName="name" type="text" class="form-control" placeholder="Name of the Project" required>
                                        </div>
                                        <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                                            <div *ngIf="form.controls.name.errors.required">
                                                Project Name  is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                        <label>Mobile Number </label>
                                        <span class="text-danger">*</span>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">+91</span>
                                            </div>
                                            <input autofocus formControlName="mobileNumber" type="text" class="form-control" placeholder="Format - XXXXXXXXXX" required maxlength=10 (keypress)="numberOnly($event)">
                                        </div>
                                        <div *ngIf="form.controls.mobileNumber.invalid && (form.controls.mobileNumber.dirty || form.controls.mobileNumber.touched)" class="text text-danger">
                                            <div *ngIf="form.controls.mobileNumber.errors.required">
                                                Mobile Number is required
                                            </div>
                                            <div *ngIf="form.controls.mobileNumber.errors.pattern || form.controls.mobileNumber.errors.maxlength || form.controls.mobileNumber.errors.minlength " class="text text-danger">
                                                Mobile number must be 10 numbers
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                        <label>Address</label>
                                        <div class="input-group">
                                            <textarea [rows]="5" autofocus formControlName="address" type="text" class="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-xl-12 form-group m-form__group"></div>
                                    <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                        <label>City</label>
                                        <div class="input-group">
                                            <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="city" [suggestions]="filteredCities" (onSelect)="onCitySelected($event)" (completeMethod)="filterCity($event)" field="name" [dropdown]="true">
                                            <ng-template let-city pTemplate="item">
                                                <div class="city-item">
                                                <div>{{city.name}}</div>
                                                </div>
                                            </ng-template>
                                            </p-autoComplete>
                                        </div>
                                        <div *ngIf="form.controls.city.invalid && (form.controls.city.dirty || form.controls.city.touched)" class="text text-danger">
                                            <div *ngIf="form.controls.city.errors.pattern" class="text text-danger">
                                                City is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                        <label>District</label>
                                        <div class="input-group">
                                            <input readonly formControlName="district" type="text" class="form-control">
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                        <label>Estimated Prospect Value</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="icofont icofont-money"></i></span>
                                            </div>
                                            <input autofocus formControlName="totalProspectValue" type="text" class="form-control" placeholder="Percentage Completed" (keypress)="numberOnly($event)">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                        <label>Percentage Completed</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="icofont icofont-tasks-alt"></i></span>
                                            </div>
                                            <input autofocus formControlName="percentageCompleted" type="text" class="form-control" placeholder="Percentage Completed" maxlength=3 (keypress)="numberOnly($event)">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                        <label>Active? </label>
                                        <span class="text-danger">*</span>
                                        <div class="input-group">
                                            <input type="checkbox" formControlName="active"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                        <button style="float: right;" type="submit" class="btn btn-primary me-1">Save Project Details</button>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-xl-3 row form-group m-form__group"></div>
                                </div>
                                <p-tabView>
                                    <p-tabPanel header="All Sales Officers">
                                        <div class="card-body p-t-0 p-b-0">
                                            <div *ngIf="project !== undefined" class="card p-t-15">
                                              <h5>Sales Officers</h5>
                                              
                                              <p-table styleClass="p-datatable-gridlines" [value]="project.employees" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                                                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
                                                  <ng-template pTemplate="header">
                                                      <tr>
                                                          <th style="width:15%">Name</th>
                                                          <th style="width:15%">Mobile</th>
                                                          <th style="width:5%">Actions</th>
                                                      </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="body" let-employee>
                                                      <tr>
                                                          <td>{{employee.name}}</td>
                                                          <td>{{employee.mobileNumber}}</td>
                                                          <td><p-button type="button" icon="pi pi-trash"
                                                            styleClass="p-button-text"
                                                            (click)="deleteEmployee(employee);$event.preventDefault()"></p-button>
                                                            </td>
                                                      </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="paginatorleft">
                                                    <button style="float: left;" type="button"
                                                        class="btn btn-primary me-1"
                                                        (click)="addSalesOfficer();$event.preventDefault()">Add
                                                        Sales Officer</button>
                                                </ng-template>
                                              </p-table>
                                          </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel header="Requirements">
                                        <div class="card-body p-t-0 p-b-0">
                                            <div *ngIf="project !== undefined" class="card p-t-15">
                                              <h5>Project Requirements</h5>
                                              
                                              <p-table styleClass="p-datatable-gridlines" [value]="project.projectProductEstimates" [paginator]="true" [rows]="25" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                                                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
                                                  <ng-template pTemplate="header">
                                                      <tr>
                                                          <th style="width:15%">Product</th>
                                                          <th style="width:15%">Estimated Area</th>
                                                          <th style="width:15%">Price</th>
                                                          <th style="width:15%">Total Estimated Value</th>
                                                      </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="body" let-projectProduct>
                                                      <tr *ngIf="projectProduct.estimatedAmount && projectProduct.estimatedAmount > 0">
                                                          <td>{{projectProduct.product?.name}}</td>
                                                          <td>{{projectProduct.estimatedAmount | number:'1.0-0'}} Sq Ft.</td>
                                                          <td>{{projectProduct.product.price | currency:'INR':'symbol-narrow' }}</td>
                                                          <td>{{ ( projectProduct.estimatedAmount * projectProduct.product.price) | currency:'INR':'symbol-narrow' }}</td>
                                                      </tr>
                                                  </ng-template>
                                              </p-table>
                                          </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel header="All Follow-Ups">
                                        <div class="card-body p-t-0 p-b-0">
                                            <div *ngIf="project !== undefined" class="card p-t-15">
                                              <h5>All Check-Ins</h5>
                                              
                                              <p-table styleClass="p-datatable-gridlines" [value]="project.followups" [paginator]="true" [rows]="5" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                                                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[5,10,20]">
                                                  <ng-template pTemplate="header">
                                                      <tr>
                                                          <th style="width:15%">Date</th>
                                                          <th style="width:15%">Activity</th>
                                                          <th style="width:15%">Follow-up Date</th>
                                                          <th style="width:15%">Visited By</th>
                                                          <th style="width:15%">Check-in Location</th>
                                                      </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="body" let-followup>
                                                      <tr>
                                                          <td>{{moment(followup.followupDate).format('DD MMM YYYY')}}</td>
                                                          <td>{{followup.activityType }}</td>
                                                          <td>{{followup.nextFollowupDate ? moment(followup.nextFollowupDate).format('DD MMM YYYY') : "N/A"}}</td>
                                                          <td>{{followup.visitedBy.name }}</td>
                                                          <td>{{followup.address }}</td>
                                                      </tr>
                                                  </ng-template>
                                              </p-table>
                                          </div>
                                        </div>
                                    </p-tabPanel>
                                </p-tabView>
                          </div>
                      </div>
                  </div>
              </div>
            </form>
      </div>
  </div>
</div>