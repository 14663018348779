import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import 'chartjs-adapter-moment';

@Component({
  selector: 'app-cumulative-report',
  templateUrl: './cumulative-report.component.html',
  styleUrls: ['./cumulative-report.component.scss']
})
export class CumulativeReportComponent implements OnInit {

  // chart: any;
  // startDate: string;
  // endDate: string;
  // employeeId: string; // Store the selected employee ID
  // distributorOrDealer: string = 'distributor'

  leastAttendedData: any[] = [];
  startDate: string;
  endDate: string;
  distributorOrDealer: string = 'distributor'; // Default to distributor
  limit: number = 10; // Default 

  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {
    // Load the cumulative chart initially
    this.createStackedBarChart();
  }

  createStackedBarChart(): void {

    this.getLeastAttended(this.startDate, this.endDate, this.distributorOrDealer).subscribe(data => {
      this.leastAttendedData = data;
    })
    
  //   this.getCumulativeCheckins(this.employeeId, this.startDate, this.endDate).subscribe(data => {

  //     if(Chart.getChart("stackedBarChart")) {
  //       Chart.getChart("stackedBarChart")?.destroy()
  //     }
  //   //   const dates = [...new Set(data.map(d => d.checkin_date))]; // Unique dates
  //   //   const checkinTypes = [...new Set(data.map(d => d.checkin_type))]; // Unique check-in types

  //   //   // Create datasets for each check-in type
  //   //   const datasets:any = checkinTypes.map(checkinType => {
  //   //     return {
  //   //       label: checkinType,
  //   //       data: dates.map(date => {
  //   //         const checkinRecord = data.find(d => d.checkin_date === date && d.checkin_type === checkinType);
  //   //         return checkinRecord ? checkinRecord.count : 0;
  //   //       }),
  //   //       backgroundColor: this.getRandomColor(), // Random color for each check-in type
  //   //     };
  //   //   });

  //   //   // Create the chart
  //   //   this.chart = new Chart('stackedBarChart', {
  //   //     type: 'bar',
  //   //     data: {
  //   //       labels: dates, // Dates on the x-axis
  //   //       datasets: datasets // Stacks of check-in types
  //   //     },
  //   //     options: {
  //   //       scales: {
  //   //         x: {
  //   //           stacked: true,
  //   //         },
  //   //         y: {
  //   //           stacked: true,
  //   //           beginAtZero: true
  //   //         }
  //   //       }
  //   //     }
  //   //   });
  //   // });

  //   // Extract unique distributor or dealer IDs and check-in types
  // //   const groupByIds = [...new Set(data.map(d => d.group_by_id))]; // Unique distributor or dealer IDs
  // //   const checkinTypes = [...new Set(data.map(d => d.checkin_type))]; // Unique check-in types

  // //   // Create datasets for each check-in type
  // //   const datasets:any = checkinTypes.map(checkinType => {
  // //     return {
  // //       label: checkinType,
  // //       data: groupByIds.map(id => {
  // //         const checkinRecord = data.find(d => d.group_by_id === id && d.checkin_type === checkinType);
  // //         return checkinRecord ? checkinRecord.count : 0;
  // //       }),
  // //       backgroundColor: this.getRandomColor(), // Assign random color to each check-in type
  // //     };
  // //   });

  // //   // Generate the chart
  // //   this.chart = new Chart('stackedBarChart', {
  // //     type: 'bar',
  // //     data: {
  // //       labels: groupByIds, // Distributor or Dealer IDs on the x-axis
  // //       datasets: datasets // Grouped bars for each check-in type
  // //     },
  // //     options: {
  // //       scales: {
  // //         x: {
  // //           stacked: false, // Grouped, not stacked
  // //         },
  // //         y: {
  // //           beginAtZero: true
  // //         }
  // //       }
  // //     }
  // //   });

  //   this.leastAttendedData = data;
  //   });



  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  filterByDateAndEmployee(): void {
    this.createStackedBarChart();
  }

  getLeastAttended(startDate?: string, endDate?: string, distributorOrDealer: string = 'distributor', limit: number = 10): Observable<any> {
    let params = new HttpParams();
    if (startDate && endDate) {
      params = params.set('start_date', startDate).set('end_date', endDate);
    }
    params = params.set('distributor_or_dealer', distributorOrDealer).set('limit', limit.toString());
    return this.http.get(`${environment.serverUrl}/check-in/least-attended`, { params });
  }

  getCumulativeCheckins(employeeId?: string, startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams();
    if (startDate && endDate) {
      params = params.set('start_date', startDate).set('end_date', endDate);
    }
    //return this.http.get(`${environment.serverUrl}/check-in/checkin-type-per-employee`, { params });
    return this.http.get(`${environment.serverUrl}/check-in/grouped-bar-chart`, { params });
    
  }
}
