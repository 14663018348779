import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, delay, lastValueFrom, map, Subject, Subscription } from 'rxjs';
import { City, Dealer } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import Helper from 'src/app/helper/Helper';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dealer-detail',
  templateUrl: './dealer-detail.component.html',
  styleUrls: ['./dealer-detail.component.scss']
})
export class DealerDetailComponent implements OnInit {

  dealer?: Dealer = undefined;

  @Output() dealerEntry: EventEmitter<any> = new EventEmitter();

  dealerAddedObserver: Subscription;

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  cities : City[]

  filteredCities: City[];

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, 
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, 
    private toastrService: ToastrService, private appService: AppService) {
      
     }

  ngOnInit(): void {
    
    lastValueFrom(combineLatest([
      this.http.get<any>(`${environment.serverUrl}/helper/cities`, { }),
     ]).pipe(
      map(([a]) => { 
        this.cities = a.data
      })
    ))
    
    this.loadDealerData()
  }

  exportPendingAndVerified(){

    if( !this.dealer.verified ){
      Helper.showMessageAlert(`Error!`,"Dealer is not verified!",`error`)
      return;
    }
    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/vnd.ms-excel'}),
      params: {},
      responseType: 'blob'
    };
    this.http.get<Blob>(`${environment.serverUrl}/redeem-request/dealer/export-for-bank/${this.dealer?.id}`,httpOptions)
    .subscribe(blob => {
      console.log(blob)
      saveAs(new Blob([blob], { type: 'vnd.ms-excel;charset=utf-8' }), `bank-transfers-${moment().format("BULK_DDMMYY_TOK001")}.xlsx`)
     });
  }
  
  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/dealer`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/dealer/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
      .subscribe({
        next: (v) => {
          console.log(v)
          if( this.form.value.id ){
            this.toastrService.success('Dealer details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Dealer added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.dealerEntry.emit(v);
          this.reloadComponent(true)
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
    //skipLocationChange:true means dont update the url to / when navigating
   console.log("Current route I am on:",this.router.url);
   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       console.log(`After navigation I am on:${this.router.url}`)
     })
   })
 }

  loadDealerData(){
    lastValueFrom(this.http.get(`${environment.serverUrl}/dealer/${this.route.snapshot.params.id}`)).then( (result:any) => {  
      this.dealer = result
      this.appService.setTitle(this.dealer.name)
      this.appService.setMenuItems([{label:'All Dealers', routerLink: '/dealers',iconClass:"pi pi-users"},{label:this.dealer.name,disabled:true}])
      this.form = this.fb.group({
        id:this.dealer.id,
        name: [this.dealer.name],
        mobileNumber: [this.dealer.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        verified: [this.dealer.verified],
        bankAccountNumber: [this.dealer.bankAccountNumber],
        ifscCode: [this.dealer.ifscCode],
        upiId: [this.dealer.upiId],
        city: [this.dealer.city],
        district: [this.dealer.city?.district?.name],
        address: [this.dealer.address],
        latitude: [this.dealer.latitude],
        longitude: [this.dealer.longitude],
        role:['Dealer']
      });
    })
    .catch(e => {
      const message = e.error?.errors?.length > 0 ?  e.error?.errors[0] : e.error?.message
      if( message)
        Helper.showMessageAlert(`Error!`,message,`error`)
    });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onCitySelected(e){
    this.form.patchValue({
      district: e.district?.name, 
    });
  }

   filterCity(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.cities.length; i++) {
      let city = this.cities[i];
      if (city.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(city);
      }
    }

    this.filteredCities = filtered;
  }

}
