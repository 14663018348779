import { SearchAndAddEmployeeComponent } from './../../../shared/components/search-and-add-employee/search-and-add-employee.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, delay, lastValueFrom, map, Subject, Subscription } from 'rxjs';
import { City, Employee, Project } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import Helper from 'src/app/helper/Helper';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  moment = moment 
  project?: Project = undefined;

  @Output() dealerEntry: EventEmitter<any> = new EventEmitter();

  employeeAddedObserver: Subscription;

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  cities : City[]

  filteredCities: City[];

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, 
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, 
    private toastrService: ToastrService, private appService: AppService) {
      
     }

  ngOnInit(): void {
    
    lastValueFrom(combineLatest([
      this.http.get<any>(`${environment.serverUrl}/helper/cities`, { }),
     ]).pipe(
      map(([a]) => { 
        this.cities = a.data
      })
    ))
    
    this.loadProjectData()
  }
  
  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/project`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/project/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
      .subscribe({
        next: (v) => {
          console.log(v)
          if( this.form.value.id ){
            this.toastrService.success('Project details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Project added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.dealerEntry.emit(v);
          this.reloadComponent(true)
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
    //skipLocationChange:true means dont update the url to / when navigating
   console.log("Current route I am on:",this.router.url);
   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       console.log(`After navigation I am on:${this.router.url}`)
     })
   })
 }

 loadProjectData(){
    lastValueFrom(this.http.get(`${environment.serverUrl}/project/${this.route.snapshot.params.id}`)).then( (result:any) => {  
      this.project = result
      this.appService.setTitle(this.project.name)
      this.appService.setMenuItems([{label:'All Projects', routerLink: '/projects',iconClass:"pi pi-users"},{label:this.project.name,disabled:true}])
      this.form = this.fb.group({
        id:this.project.id,
        name: [this.project.name],
        mobileNumber: [this.project.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [this.project.active],
        city: [this.project.city],
        district: [this.project.city?.district?.name],
        address: [this.project.address],
        totalProspectValue: [this.project.totalProspectValue],
        percentageCompleted: [this.project.percentageCompleted],
        role:['Project']
      });
    })
    .catch(e => {
      const message = e.error?.errors?.length > 0 ?  e.error?.errors[0] : e.error?.message
      if( message)
        Helper.showMessageAlert(`Error!`,message,`error`)
    });
  }

  addSalesOfficer(){
    const modalRef = this.modalService.open(SearchAndAddEmployeeComponent,{ centered: true,size: 'xl'});
    modalRef.componentInstance.name = 'Add Employee';
    
    if( this.employeeAddedObserver ){
      this.employeeAddedObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/

    this.employeeAddedObserver = modalRef.componentInstance.employeeSelected.subscribe((employees: Employee[]) => {
      const employeeIds = []
      employees.map( (d) =>{
        employeeIds.push(d.id)
      })
      
      this.http.post(`${environment.serverUrl}/project/${this.project.id}/add-employees`, JSON.stringify({"employees":employeeIds}))
      .subscribe({
        next: (v) => {
          this.toastrService.success('Sales Officer added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          this.loadProjectData()
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
    });
  }

  deleteEmployee(selectedEmployee){
    Helper.showConfirmationAlert(`Are you sure?`,`You want remove selected Sales Officer from the project?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.http.delete(`${environment.serverUrl}/project/${this.project.id}/remove-employee/${selectedEmployee.id}`).subscribe({
          next: () => {
            this.project.employees.forEach( (employee, index, dealers) => {
              if(employee.id === selectedEmployee.id){
                this.project.employees.splice(index,1);
              }
            });
          },
          error: (e) => {
            Helper.processError(e)
          }
        }); 
      }
    })
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onCitySelected(e){
    this.form.patchValue({
      district: e.district?.name, 
    });
  }

   filterCity(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.cities.length; i++) {
      let city = this.cities[i];
      if (city.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(city);
      }
    }

    this.filteredCities = filtered;
  }

}
