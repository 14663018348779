import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Dealer, Employee } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import Helper from '../../../helper/Helper';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {

  employee: Employee = undefined;

  @Output() employeeEntry: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  allDealers : []
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, public activeModal: NgbActiveModal,
    private toastrService: ToastrService) {
      
  }

  ngOnInit(): void {
    if( this.employee ){
      this.form = this.fb.group({
        id:this.employee.id,
        name: [this.employee.name],
        mobileNumber: [this.employee.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        role: [this.employee.user.role, [Validators.required,this.notAllowed(/^0/)]],
        active: [this.employee.active],
        internalCode: [this.employee.internalCode],
      });
    }
    else{
      this.form = this.fb.group({
        name: [''],
        mobileNumber: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [true],
        role:['0',[Validators.required,this.notAllowed(/^0/)]],
        internalCode: [''],
      });
    }

  }

  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/employee`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/employee/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
      .subscribe({
        next: (v) => {
          console.log(v)
          this.activeModal.close('Success')
          if( this.form.value.id ){
            this.toastrService.success('Employee details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Employee added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.employeeEntry.emit(v);
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  notAllowed(input: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = input.test(control.value);
      return forbidden ? {notAllowed: {value: control.value}} : null;
    };
  }
}
