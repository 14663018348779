import { SearchAndAddDealerComponent } from './../../../shared/components/search-and-add-dealer/search-and-add-dealer.component';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { delay, lastValueFrom, Subject, Subscription } from 'rxjs';
import { Dealer, Distributor } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import Helper from 'src/app/helper/Helper';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-distributor-detail',
  templateUrl: './distributor-detail.component.html',
  styleUrls: ['./distributor-detail.component.scss']
})
export class DistributorDetailComponent implements OnInit {

  distributor?: Distributor = undefined;

  @Output() distributorEntry: EventEmitter<any> = new EventEmitter();

  dealerAddedObserver: Subscription;

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, 
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, 
    private toastrService: ToastrService, private appService: AppService) {
      
     }

  ngOnInit(): void {
    
    this.loadDealerData()
  }

  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/distributor`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/distributor/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
      .subscribe({
        next: (v) => {
          console.log(v)
          if( this.form.value.id ){
            this.toastrService.success('Distributor details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Distributor added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.distributorEntry.emit(v);
          this.reloadComponent(true)
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
    //skipLocationChange:true means dont update the url to / when navigating
   console.log("Current route I am on:",this.router.url);
   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       console.log(`After navigation I am on:${this.router.url}`)
     })
   })
 }

  loadDealerData(){
    lastValueFrom(this.http.get(`${environment.serverUrl}/distributor/${this.route.snapshot.params.id}`)).then( (result:any) => {  
      this.distributor = result
      this.appService.setTitle(this.distributor.name)
      this.appService.setMenuItems([{label:'All Distributors', routerLink: '/distributors',iconClass:"pi pi-users"},{label:this.distributor.name,disabled:true}])
      this.form = this.fb.group({
        id:this.distributor.id,
        name: [this.distributor.name],
        mobileNumber: [this.distributor.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [this.distributor.active],
        address: [this.distributor.address],
        latitude: [this.distributor.latitude],
        longitude: [this.distributor.longitude],
        role:['Distributor']
      });
    })
    .catch(e => {
      const message = e.error?.errors?.length > 0 ?  e.error?.errors[0] : e.error?.message
      if( message)
        Helper.showMessageAlert(`Error!`,message,`error`)
    });
  }

  addDealer(){
    const modalRef = this.modalService.open(SearchAndAddDealerComponent,{ centered: true,size: 'xl'});
    modalRef.componentInstance.name = 'Add / Dealer';
    
    if( this.dealerAddedObserver ){
      this.dealerAddedObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/

    this.dealerAddedObserver = modalRef.componentInstance.dealerSelected.subscribe((dealers: Dealer[]) => {
      const dealerIds = []
      dealers.map( (d) =>{
        dealerIds.push(d.id)
      })
      
      this.http.post(`${environment.serverUrl}/distributor/${this.distributor.id}/add-dealers`, JSON.stringify({"dealers":dealerIds}))
      .subscribe({
        next: (v) => {
          this.toastrService.success('Dealer added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          this.loadDealerData()
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
    });
  }

  resetPassword(){
      Helper.showConfirmationAlert(`Are you sure?`,`You want reset the password for distributor ${this.distributor.name}?`,`question`,"Yes","No")
      .subscribe((result) => {
        if( result ){
          this.http.post(`${environment.serverUrl}/distributor/reset-password/${this.distributor.id}`,{}).subscribe({
            next: () => {
              this.toastrService.success('Distributor password updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
            },
            error: (e) => {
              Helper.processError(e)
            }
          }); 
        }
      })
    }

  deleteDealer(selectedDealer){
    Helper.showConfirmationAlert(`Are you sure?`,`You want remove selected dealer from the distributor?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.http.delete(`${environment.serverUrl}/distributor/${this.distributor.id}/remove-dealer/${selectedDealer.id}`).subscribe({
          next: () => {
            this.distributor.dealers.forEach( (dealer, index, dealers) => {
              if(dealer.id === selectedDealer.id){
                this.distributor.dealers.splice(index,1);
              }
            });
          },
          error: (e) => {
            Helper.processError(e)
          }
        }); 
      }
    })
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}


