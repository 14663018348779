import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, lastValueFrom, map, Subject } from 'rxjs';
import { City, Dealer, District, Project } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import Helper from '../../../helper/Helper';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {

  project: Project | undefined = undefined;

  @Output() projectEntry: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  cities : City[]

  filteredCities: City[];
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal,private fb: FormBuilder, public activeModal: NgbActiveModal,
    private toastrService: ToastrService) {
      
  }

  ngOnInit(): void {

    lastValueFrom(combineLatest([
      this.http.get<any>(`${environment.serverUrl}/helper/cities`, { }),
     ]).pipe(
      map(([a]) => { 
        this.cities = a.data
      })
    ))

    if( this.project ){
      this.form = this.fb.group({
        id:this.project.id,
        name: [this.project.name],
        address: [this.project.address],
        mobileNumber: [this.project.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [this.project.active],
        city: [this.project.city],
        district: [this.project.city?.district?.name],
        role:['Project']
      });
    }
    else{
      this.form = this.fb.group({
        name: [''],
        address: [''],
        mobileNumber: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        active: [true],
        city: [''],
        district:[''],
        role:['Project']
      });
    }

  }

  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/project`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/project/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
      .subscribe({
        next: (v) => {
          console.log(v)
          this.activeModal.close('Success')
          if( this.form.value.id ){
            this.toastrService.success('Project details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }
          else{
            this.toastrService.success('Project added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
          }

          this.projectEntry.emit(v);
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e)
        },
        complete: () => console.info('complete')
      });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onCitySelected(e){
    this.form.patchValue({
      district: e.district?.name, 
    });
  }
  filterCity(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.cities.length; i++) {
      let city = this.cities[i];
      if (city.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(city);
      }
    }

    this.filteredCities = filtered;
  }

}
