import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { Employee } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../app.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ColumnMode, DatatableComponent, NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-list-employee',
  templateUrl: './list-employee.component.html',
  styleUrls: ['./list-employee.component.scss']
})
export class ListEmployeeComponent extends ListBaseComponent<Employee> implements OnInit {

  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('DatatableComponent') ngxDatatable: DatatableComponent;

  private currentComponentWidth
  
  employeeRegistrationObserver: Subscription;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  bShowFilters = false

  name:string = '';
  mobileNumber:string = '';
  activeEmployee:string = '';
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal) {
      super(http,breadcrumbService,appService,offcanvasService,router,route, ); 
  }

  ngOnInit(): void {
    
    this.appService.setTitle("All Employees")
    this.appService.setMenuItems([{label:'All Employees',disabled:true,routerLink: '/employees',iconClass:"pi pi-users"}])

    this.pageCallback({ offset: 0 });

    this._message.subscribe((message) => (this.message = message));
    this._message.pipe(debounceTime(5000)).subscribe(() => {
			this.alertError = false;
		});
  }

  ngAfterViewChecked() {
    // Check if the table size has changed,
    if (this.ngxDatatable && this.ngxDatatable.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.ngxDatatable.columnMode = ColumnMode.force;
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.ngxDatatable.recalculate();
      
    }
  }

  showFilters(){
    this.bShowFilters = !this.bShowFilters
    if( !this.bShowFilters){
      this.clearFilter();
    }
    setTimeout(() => {
      this.ngxDatatable.recalculate()
    }, 100);
  }
  clearFilter(){
    
    this.mobileNumber = ""
    this.name = ""
    this.activeEmployee = ""
    super.clearFilter();
  }

  applyFilterAfterDelay(){
    setTimeout(() => {
      this.applyFilter();
    }, 10); 
  }

  populateParamsForQuery(params: HttpParams): HttpParams{

    if( this.name.length > 0){
      params = params.set('name', `${this.name}`);
    }
    if( this.mobileNumber.length > 0){
      params = params.set('mobileNumber', `${this.mobileNumber}`);
    }
    
    if( this.activeEmployee.length > 0 ){
      params = params.set('active', `${this.activeEmployee}`);
    }
    return params;
  }

  onActivate(event){
    if(event.type == 'click') {
      console.log(event.row);
      this.editEmployee(event.row)
    }
  }

  addNewEmployee(){
    
    const modalRef = this.modalService.open(AddEmployeeComponent,{ centered: true,size: 'lg'});
    modalRef.componentInstance.name = 'Add / Edit Employee';
    
    if( this.employeeRegistrationObserver ){
      this.employeeRegistrationObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/

    this.employeeRegistrationObserver = modalRef.componentInstance.employeeEntry.subscribe((employee) => {
      this.reloadTable()
    });
    
  }

  editEmployee(employee){
    
    this.router.navigate(['employees',employee.id])
  }

  protected get loadingUrl(){
    return `${environment.serverUrl}/employee`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/employee/export`;
  }

  protected get exportFileName(){
    return `Employee Report`;
  }

}
