import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth-service.service';

type UserFields = 'mobileNumber' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'mobileNumber': '',
    'password': '',
  };
  public errorMessage: any;
  public error: {};

  constructor(public authService: AuthService,
    private fb: FormBuilder,
    private router: Router) {
    this.loginForm = fb.group({
      mobileNumber: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {}

  login() {
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.errorMessage = '';
    this.authService.login(this.loginForm.value['mobileNumber'], this.loginForm.value['password'])
    .subscribe({
      next: () => {
        this.router.navigate(['/dashboard']);
      },
      error: (e) => {
        console.error(e);
        this.errorMessage = e['errorDesc'];
      }
    })
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
