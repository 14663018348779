import { HttpContextToken } from "@angular/common/http";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export const IS_UPLOAD_REQUEST = new HttpContextToken<boolean>(() => false);

export interface LoginResponse {
    accessToken: string;
    refreshToken?: string;
    user: User;
}

export class User {  
    id?: number;
    username?: string;
    email?: string;
    name?: string;
    profilePicture?: string;
    bannerPicture?: string;
    lastLoginDate?: string;
    staffId?: string;
    userType?: string;
    role?: string;
}

export class LookupData {  
    id: number;
    value?: string;
    acronym?: string;
    description?: string;
    name?: string;
}

export class Attachment{
    id: string;
    fileName?: string;
    type?: string;
    fileSize?: string;
    storagePath?: string;
}

export interface Contractor {
    id:number;
    name:string;
    mobileNumber:string;
    internalCode:string;
    bankAccountNumber: string;
    ifscCode: string;
    upiId: string;
    verified?: boolean;
    createdAt:string;
    updatedAt:string;
}

export interface Distributor {
    id:number;
    name:string;
    mobileNumber:string;
    internalCode:string;
    active?: boolean;
    createdAt:string;
    updatedAt:string;
    dealersCount: number;
    address: string;
    latitude: string;
    longitude: string;
    dealers: Dealer[]
}

export interface Dealer {
    id:number;
    name:string;
    mobileNumber:string;
    internalCode:string;
    verified: boolean;
    bankAccountNumber: string;
    ifscCode: string;
    upiId: string;
    address: string;
    latitude: string;
    longitude: string;
    city:{
        id: number;
        name: string;
        district: LookupData
    }
    distributors: Distributor[];
    employees: Employee[];
    createdAt:string;
    updatedAt:string;
}

export interface Project {
    id:number;
    name:string;
    mobileNumber:string;
    active: boolean;
    address: string;
    totalProspectValue: string;
    percentageCompleted: string;
    city:{
        id: number;
        name: string;
        district: LookupData
    }
    employees: Employee[];
    createdAt:string;
    updatedAt:string;
}

export interface Employee {
    id:number;
    name:string;
    mobileNumber:string;
    internalCode:string;
    active?: boolean;
    createdAt:string;
    updatedAt:string;
    user:User;
    dealersCount: number;
    dealers: Dealer[];
    distributors: Distributor[];
}

export interface Invoice{
    id?: number;
    invoiceNumber?: string;
    invoiceType?: string;
    invoicedByDistributor?: Distributor;
    invoicedByDealer?: Dealer;
    distributor?: Distributor;
    dealer?: Dealer;
    items?: InvoiceTransactionItem[];
    invoiceDate?: Date;
    tax?: number;
    verified?:boolean;
    grandTotal?: number;
    createdAt?: string;
    updatedAt?: string;
}

export interface ReturnInvoice{
    id?: number;
    returnInvoiceType?: string;
    returnInvoicedByDistributor?: Distributor;
    returnInvoicedByDealer?: Dealer;
    distributor?: Distributor;
    items?: InvoiceTransactionItem[];
    invoiceDate?: Date;
    createdAt?: string;
    updatedAt?: string;
}

export interface InvoiceTransactionItem{
    id: number | string;
    product: Product;
    quantity: number;
    price: number;
    tax: number;
    total: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface City {
    id:number;
    name:string;
    district?: District
}

export interface District {
    id:number;
    name:string;
}

export interface ProductCategory {
    id:number;
    name:string;
}

export interface Product {
    id:number;
    name:string;
    internalCode?:string;
    attr1?:string;
    attr2?:string;
    attr3?:string;
    attr4?:string;
    attr5?:string;
    image?:string;
    commission?:string;
    maximumDealerPrice?:number;
    productCategory?:ProductCategory;
    techSheet?:string;
    description?:string;
    price?: number;
    dealerCommission?: number;
    websiteUrl?:string;
    createdAt?:string;
    updatedAt?:string;
}
export interface QRCode {
    id:number;
    qrCode: string;
    redeemed:boolean;
}

export interface QRCodeBatch {
    id:number;
    batchName:string;
    numberOfCodes:number;
    chatBotNumber:string;
    product:Product;
    qrcodes:QRCode[];
    used:boolean;
    createdAt:string;
    updatedAt:string;
}

export interface Chat {
    type: string;
    messageType: string;
    value: string;
}


export interface RedeemRequest {
    id:number;
    uuid:string;
    customer:Contractor;
    requestDate:string;
    requestStatus:string;
    remarks:string;
    log:string;
    qrCode:string;
    paymentDate: string;
    transactionNumber:string;
    product:Product;
    createdAt:string;
    updatedAt:string;
}

export interface Alert {
    type: string;
    message: string;
}

export interface Comment {
    id: number;
    postedBy: User;
    lastModified: string;
    text: string;
    replies?: Array<Comment>;
}
