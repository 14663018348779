import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { IS_UPLOAD_REQUEST, Product, ProductCategory } from 'src/app/types/types';
import { environment } from '../../../../environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { fileURLToPath } from 'url';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  public ClassicEditor = ClassicEditor;
  
  product: Product = undefined;

  @Output() productEntry: EventEmitter<Product> = new EventEmitter();

  form: FormGroup;

  categories: ProductCategory[]

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';
  imageSrc = undefined;

  selectedImage: File = undefined;
  selectedTechSheet: File = undefined;

  techSheetAvaialble = false;
  techSheetUrl = undefined

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,private http: HttpClient,private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.loadAllCategories()

    if( this.product ){
      this.form = this.fb.group({
        id:this.product.id,
        name: [this.product.name,[Validators.required]],
        categoryId:[this.product.productCategory.id],
        attr1:[this.product.attr1],
        attr2:[this.product.attr2],
        attr3:[this.product.attr3],
        attr4:[this.product.attr4],
        attr5:[this.product.attr5],
        price:[this.product.price],
        dealerCommission:[this.product.dealerCommission],
        websiteUrl:[this.product.websiteUrl],
        commission:[this.product.commission],
        description:[this.product.description,[Validators.required]],
      });
      if( this.product.image ){
        this.imageSrc = this.product.image
      }
      if( this.product.techSheet ){
        this.techSheetAvaialble = true
        this.techSheetUrl = this.product.techSheet
      }
    }
    else{
      this.form = this.fb.group({
        name: ['',[Validators.required]],
        categoryId:[''],
        attr1:[''],
        attr2:[''],
        attr3:[''],
        attr4:[''],
        attr5:[''],
        price:['0.0'],
        dealerCommission:['0.0'],
        websiteUrl:[''],
        commission:['0.0'],
        description:['',[Validators.required]],
      });
    }
  }

  loadAllCategories(){
    this.http.get<ProductCategory[]>(`${environment.serverUrl}/product/category`).subscribe({
      next:(response:any) => {
        this.categories = response.data
      }
    })
  }
  async addImage(event): Promise<void> {
    if (event.target.files.length === 0)
      return;
    this.selectedImage = event.target.files[0];
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    this.imageSrc = await this.toBase64(event.target.files[0]);
  }

  addTechSheet(event): void {
    this.selectedTechSheet = event.target.files[0];
    this.techSheetAvaialble = true;
  }

  removeImage(){
    this.imageSrc = undefined;
    this.selectedImage = undefined
  }

  removeTechSheet(){
    this.selectedTechSheet = undefined
    this.techSheetAvaialble = false;
  }
  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/product/`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/product/${this.form.value.id}`
    }
    
    const formData: FormData = new FormData();    

    for (const field in this.form.controls) { // 'field' is a string
      const control = this.form.get(field); // 'control' is a FormControl  
      formData.append(field,control.value)
    }
    if( this.selectedImage)
      formData.append("image",this.selectedImage);

    if(this.selectedTechSheet) 
      formData.append("techSheet",this.selectedTechSheet)
    
    this.http.post(postUrl, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
          next: (v:Product) => {
            console.log(v)
            this.activeModal.close('Success')
            this.toastrService.success('Product added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
            this.productEntry.emit(v);
          },
          error: (e) => {
            console.error(e);
            this.showMessage("error",`Adding new Product failed. Message:${e.message}`);
          },
          complete: () => console.info('complete')
        });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  atInternal: boolean = false;
  atExternal: boolean = false;
  ttCeramic: boolean = false;
  ttVitrified: boolean = false;
  ttNaturalStone: boolean = false;
  tsSmall: boolean = false;
  tsLarge: boolean = false;
  aaWall: boolean = false;
  aaFloor: boolean = false;
  tsTileOnTile: boolean = false;
  tsCementBoard: boolean = false;
  tsPlaster: boolean = false;
}


