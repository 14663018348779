<!--div class="container">
  <div class="row">
    <div class="col-md-4">
      <label for="startDate">Start Date</label>
      <input type="date" class="form-control" [(ngModel)]="startDate" placeholder="Start Date">
    </div>
    <div class="col-md-4">
      <label for="endDate">End Date</label>
      <input type="date" class="form-control" [(ngModel)]="endDate" placeholder="End Date">
    </div>
    <div class="col-md-4">
      <label for="distributorOrDealer">Distributor/Dealer</label>
      <select class="form-control" [(ngModel)]="distributorOrDealer">
        <option value="distributor">Distributor</option>
        <option value="dealer">Dealer</option>
      </select>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <button class="btn btn-primary" (click)="filterByDateAndEmployee()">Filter</button>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <canvas id="stackedBarChart"></canvas>
    </div>
  </div>
</div-->


<div class="container">
  <div class="row">
    <div class="col-md-4">
      <label for="startDate">Start Date</label>
      <input type="date" class="form-control" [(ngModel)]="startDate" placeholder="Start Date">
    </div>
    <div class="col-md-4">
      <label for="endDate">End Date</label>
      <input type="date" class="form-control" [(ngModel)]="endDate" placeholder="End Date">
    </div>
    <div class="col-md-4">
      <label for="distributorOrDealer">Distributor/Dealer</label>
      <select class="form-control" [(ngModel)]="distributorOrDealer">
        <option value="distributor">Distributor</option>
        <option value="dealer">Dealer</option>
      </select>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <button class="btn btn-primary" (click)="getLeastAttended(startDate, endDate,distributorOrDealer)">Generate Report</button>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Check-ins Count</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of leastAttendedData">
            <td>{{ item.group_by_id }}</td>
            <td>{{ item.checkin_count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
