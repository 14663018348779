import { lastValueFrom, combineLatest, of, map } from 'rxjs';
import { City, Employee, District } from './../../../types/types';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from '../../../helper/Helper';


@Component({
  selector: 'app-search-and-add-employee',
  templateUrl: './search-and-add-employee.component.html',
  styleUrls: ['./search-and-add-employee.component.scss']
})
export class SearchAndAddEmployeeComponent implements OnInit {

  @Output() employeeSelected: EventEmitter<Employee[]> = new EventEmitter();
  
  employees : Employee[] = []

  cities : City[] = []

  districts : District[] = []

  loading = false

  selectedEmployees : Employee[] = []

  constructor(protected httpClient: HttpClient,public activeModal: NgbActiveModal) {
    
  }

  ngOnInit() {

    this.loading = true
    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/employee`, { }),
      this.httpClient.get<[]>(`${environment.serverUrl}/helper/cities`, { }),
      this.httpClient.get<[]>(`${environment.serverUrl}/helper/districts`, { })
     ]).pipe(
      map(([a,b,c]) => { 
        this.employees = a.data
        this.cities = b
        this.districts = c
        this.loading = false
      })
    ))
  }

  addSelecteddEmployee(){
    
    if( this.selectedEmployees.length == 0 ){
      Helper.showMessageAlert(`Error!`,"You haven't selected any employees",`error`)
      return
    }

    Helper.showConfirmationAlert(`Are you sure?`,`You want to add ${this.selectedEmployees.length} employees?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.employeeSelected.emit(this.selectedEmployees)
        this.activeModal.dismiss('Closed')
      }
    })
  }


}
