import { Observable, Subject } from "rxjs";

const Swal = require('sweetalert2')

export default class Helper {


    /**
     * Format bytes as human-readable text.
     * 
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use 
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     * 
     * @return Formatted string.
     */
    static humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  static classicEditorDefaultConfig(){
    const config = 
    {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'imageUpload',
          '|',
          'undo',
          'redo'
        ]
      },
      
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      allowedContent: true,
      width: "100%",
      language: 'en'
    };

    return config;
  }


  public static processError(e){
    let message = "" 
    if( e.status == 404 ){
      message = "Resource Not Found"
    }
    else{
      message = e.error?.message?.length > 0 ?  e.error.message : "Unknown Error - Please contact the developer"
    }
    if( message){
      Helper.showMessageAlert(`Error!`,message,`error`)
    }
  }

  public static showMessageAlert(title, message, icon, iconHtml = ""){
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
      iconHtml: iconHtml,
      customClass: {
        icon: 'no-border'
      },
      showCancelButton: false,
      confirmButtonColor: '#0379A5',
      confirmButtonText: 'Ok',
    })
  }

  public static showConfirmationAlert(title, message, icon,confirmButtonText,cancelButtonText, iconHtml = "") : Observable<boolean>{
    const subject = new Subject<boolean>();
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
      iconHtml: iconHtml,
      showCancelButton: true,
      confirmButtonColor: '#0379A5',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    }).then((result) => {
      subject.next(result.value);
    })
    return subject.asObservable();
  }
  
}