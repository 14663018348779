<div class="row">
  <div class="col-md-12 col-xl-12">
      <div class="card">
          <div class="card-header b-l-primary border-3">
              <button style="float: right;" type="button" class="btn btn-primary m-r-25" (click)="importBankFile();$event.preventDefault()"><i class="p-r-5 fa fa-plus"></i><span><strong>Import Bank Txns</strong></span></button>
              <button style="float: right;" type="button" class="btn btn-primary m-r-25" (click)="exportPendingAndVerified();$event.preventDefault()"><i class="p-r-5 fa fa-plus"></i><span><strong>Export Verified Pending Txns</strong></span></button>
              <input #attachments id="bankExcelSheet" type="file" (change)="uploadExcelSheet($event)" style="display: none;" accept=".xlsx, .xls, .csv"/>
          </div>
          <div class="card-body">
              <div #tableWrapper style="padding-left:15px;padding-right:15px;">
                  <ngx-datatable #DatatableComponent
                      class="ngx-datatable content-table bootstrap fixed-header virtualized"
                      [loadingIndicator]="loading"
                      [columnMode]="'force'"
                      [headerHeight]="bShowFilters ? 95 : 50"
                      [footerHeight]="50"
                      [rowHeight]="65"
                      [externalPaging]="true"
                      [externalSorting]="true"
                      [count]="page.count"
                      [offset]="page.offset"
                      [limit]="page.limit"
                      [sortType]="'single'"
                      [reorderable]="true"
                      (page)="pageCallback($event)"
                      (sort)="sortCallback($event)"
                      [sorts]="[{prop: 'id', dir: 'desc'}]"
                      (activate)="onActivate($event)"
                      [scrollbarH]="true"
                      [rowClass]="getRowClass"
                      [rows]="items"
                      >
                      <ngx-datatable-column prop="id" name="Sl. No" [width]="10" [resizeable]="false" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.id}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="dealer?.name" name="Dealer Name" [width]="30" [resizeable]="true" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong><a style="text-decoration:underline" (click)="editDealer(row.dealer);$event.preventDefault()">{{row.dealer?.name}} {{row.dealer?.verified ? '(V)' : '(NV)'}}</a></strong>
                          </ng-template>
                          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                              <span class="datatable-header-cell-wrapper">
                                <span class="datatable-header-cell-label" (click)="sort()">{{'Dealer Name' | translate
                                  }}</span>
                              </span>
                              <div class="filter-background w-100">
                                <input [ngClass]="{'d-none':!bShowFilters}" (change)="dealerName = $event.target.value"
                                [value]="dealerName" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="dealerName=$event.target.value;applyFilterAfterDelay()">
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="dealer?.mobileNumber" name="Mobile Number" [width]="30" [resizeable]="true" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.dealer?.mobileNumber}}</strong>
                          </ng-template>
                          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                              <span class="datatable-header-cell-wrapper">
                                <span class="datatable-header-cell-label" (click)="sort()">{{'Mobile Number' | translate
                                  }}</span>
                              </span>
                              <div class="filter-background w-100">
                                <input [ngClass]="{'d-none':!bShowFilters}" (change)="mobileNumber = $event.target.value"
                                [value]="mobileNumber" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="mobileNumber=$event.target.value;applyFilterAfterDelay()">
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="requestDate" name="Claimed Date" [width]="30" [resizeable]="true" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{moment(row.requestDate).format('DD MMM YYYY')}}</strong>
                          </ng-template>
                          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                              <span class="datatable-header-cell-wrapper">
                                <span class="datatable-header-cell-label" (click)="sort()">{{'Claimed Date' | translate }}</span>
                              </span>
                              <div class="filter-background w-100">
                                <input [ngClass]="{'d-none':!bShowFilters}" id="dateRangeInput" class="form-control"
                                  name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                  (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                  outsideDays="collapsed" [startDate]="fromDate!" tabindex="-1" (click)="datepicker.toggle()"
                                  placeholder="Select date range" container="body" />
                                <ng-template #t let-date let-focused="focused">
                                  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                    (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                  </span>
                                </ng-template>
                              </div>
                            </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="amount" name="Amount" [width]="30" [resizeable]="true" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.amount != null && row.amount > 0 ? row.amount : row.coupon?.amount}}</strong>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="requestStatus" name="Request Status" [width]="30" [resizeable]="true" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row?.requestStatus == 'Open' ? 'Unpaid' : row?.requestStatus}}</strong>
                          </ng-template>
                          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                              <span class="datatable-header-cell-wrapper">
                                <span class="datatable-header-cell-label" (click)="sort()">{{'Status' | translate }}</span>
                              </span>
                              <div class="filter-background w-100">
                                <select [ngClass]="{'d-none':!bShowFilters}" class="form-control"
                                  (change)="requestStatus = $event.target.value; applyFilter();" [value]="requestStatus"
                                  style="text-align: center;" [value]="requestStatus">
                                  <option value="all">{{'Any' | translate}}</option>
                                  <option value="open">{{'All Unpaid' | translate}}</option>
                                  <option value="paid">{{'All Paid' | translate}}</option>
                                  <option value="SentToBank">{{'Sent To Bank' | translate}}</option>
                                </select>
                              </div>
                            </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column prop="transactionNumber" name="Transaction Number" [width]="30" [resizeable]="true" [sortable]="false">
                        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                            <strong>{{row?.transactionNumber}}</strong>
                        </ng-template>
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span class="datatable-header-cell-wrapper">
                              <span class="datatable-header-cell-label" (click)="sort()">{{'Transaction Number' | translate
                                }}</span>
                            </span>
                            <div class="filter-background w-100">
                              <input [ngClass]="{'d-none':!bShowFilters}" (change)="transactionNumber = $event.target.value"
                              [value]="transactionNumber" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="transactionNumber=$event.target.value;applyFilterAfterDelay()">
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                      <ngx-datatable-column prop="action" name="Action" [width]="30" [resizeable]="true" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <div *ngIf="row.requestStatus === 'Paid' || row.requestStatus === 'SentToBank'" ngbDropdown class="d-inline-block" placement="bottom" container="body">
                                  <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle >Mark As</button>
                                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <button (click)="markAsUnPaid(row.id);$event.preventDefault()" class="dropdown-item">Unpaid</button>
                                    <button *ngIf="row.requestStatus === 'SentToBank'"  (click)="markAsPaid(row.id);$event.preventDefault()" class="dropdown-item">Paid</button>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="createdAt" name="Created" [width]="30" [resizeable]="true" [sortable]="false">
                          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <strong>{{row.createdAt}}</strong>
                          </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-footer>
                          <ng-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
                            let-curPage="curPage" let-offset="offset" ngx-datatable-footer-template>
                            <div class="controls">
                              <div>
                                <label class="datatable-body-cell-label " for="per-page">
                                  {{'Items Per Page' | translate}}
                                </label>
                                <select id="items-per-page" class="form-control items-per-page"
                                  (change)="onLimitChange($event.target.value)" [value]="page.limit"
                                  style="display:inline-block;width: 50px;text-align: center;">
                                  <option *ngFor="let option of pageLimitOptions" [value]="option.value">
                                    {{ option.value }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <app-datatable-pager (download)="exportAs($event)" [page]="curPage" [visiblePagesCount]="3"
                              [size]="pageSize" [count]="rowCount" [hidden]="false" (showFilter)="showFilters()"
                              (clearFilter)="clearFilter()" [filtersShowing]="bShowFilters" [filterApplied]="bFilterApplied"
                              (change)="onPageChange($event)">
                            </app-datatable-pager>
                          </ng-template>
                        </ngx-datatable-footer>
                  </ngx-datatable>
              </div>
          </div>
      </div>
  </div>
</div>
