<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<div *ngIf="(invoiceId == undefined) || (invoiceId !== undefined && invoice !== undefined)">
    <form [formGroup]="form">
        <div class="card-body">
            <div *ngIf="invoice == undefined else edit_invoice">
                <h4 style="text-align: center;">Add Return Invoice</h4>
            </div>
            <ng-template #edit_invoice>
                <h4 style="text-align: center;">Edit Return Invoice</h4>
            </ng-template>
            <div>
                <div *ngIf="invoice"
                    style="display:flex;width:100%;height:100%;justify-content:center;align-items:center;font-size:25px;color:#fff;flex-direction: column;padding-bottom:20px;">
                    <span style="width: 100%;text-align: center;background: darkred;color: white;">return Invoices cannot be modified</span>
                </div>
                <div *ngIf="invoice && !invoice.verified && loggedInUser.userType === 'Employee' && !editEnabled"
                    style="display:flex;width:100%;height:100%;justify-content:center;align-items:center;font-size:25px;color:#fff;flex-direction: row;padding: 20px;background: #f0f0f0;border-radius: 5px;margin-bottom: 10px;">
                    <button type="button" class="btn btn-primary me-1" (click)="invoiceVerified()">Mark Invoice as
                        Verified</button>
                    <button type="button" class="btn btn-danger" (click)="editInvoice()">Edit Invoice</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card shadow shadow-showcase">
                        <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'"
                            (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                        <div class="card-body row" style="margin-top:30px;">
                            <div *ngIf="invoice && invoice.verified" class="fadeMe"></div>
                            <div *ngIf="invoice && !invoice.verified && loggedInUser.userType === 'Employee' && !editEnabled"
                                class="fadeMe"></div>
                            <div class="col-sm-6 col-xl-6 form-group m-form__group">
                                <label>Return Invoice Date </label>
                                <span class="text-danger">*</span>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                    </div>
                                    <input (change)="invoiceDateChanged()" onfocus="this.showPicker()"
                                        formControlName="invoiceDate" class="form-control digits" type="date"
                                        [max]="today">
                                </div>
                                <div *ngIf="form.controls.invoiceDate.invalid && (form.controls.invoiceDate.dirty || form.controls.invoiceDate.touched)"
                                    class="text text-danger">
                                    <div *ngIf="form.controls.invoiceDate.errors.required">
                                        Return Invoice Date is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-xl-6 form-group m-form__group"></div>
                            <div *ngIf="invoicedByCompany" class="col-sm-6 col-xl-6 form-group m-form__group">
                                <label>Returned Invoiced By </label>
                                <span class="text-danger">*</span>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                    </div>
                                    <select [attr.disabled]="invoice" formControlName="returnInvoiceType"
                                        class="form-control digits">
                                        <option selected value="0">--Select--</option>
                                        <option value="DistributorToCompany">By Distributor</option>
                                        <option value="DealerToCompany">By Dealer</option>
                                    </select>
                                </div>
                                <div *ngIf="form.controls.returnInvoiceType.invalid && (form.controls.returnInvoiceType.dirty || form.controls.returnInvoiceType.touched)"
                                    class="text text-danger">
                                    <div *ngIf="form.controls.returnInvoiceType.errors.required">
                                        Invoice Type is required
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="invoicedByCompany" class="col-sm-6 col-xl-6 form-group m-form__group"></div>
                            <div *ngIf="!invoicedByCompany || (form.value.returnInvoiceType && form.value.returnInvoiceType == 'DealerToCompany')" class="col-sm-6 col-xl-6 form-group m-form__group">
                                <label>Select Dealer </label>
                                <span class="text-danger">*</span>
                                <div class="input-group">
                                    <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                                        formControlName="returnInvoicedByDealer" [suggestions]="filteredDealers"
                                        (onSelect)="onDealerSelected($event)" (completeMethod)="filterDealers($event)"
                                        field="name" [dropdown]="true">
                                        <ng-template let-dealer pTemplate="dealer">
                                            <div class="dealer-item">
                                                <div>{{dealer.name}}</div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div *ngIf="form.controls.returnInvoicedByDealer.invalid && (form.controls.returnInvoicedByDealer.dirty || form.controls.returnInvoicedByDealer.touched)"
                                    class="text text-danger">
                                    <div *ngIf="form.controls.returnInvoicedByDealer.errors.required">
                                        Dealer is required
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!invoicedByCompany || (form.value.returnInvoiceType && form.value.returnInvoiceType == 'DealerToCompany')" class="col-sm-6 col-xl-6 form-group m-form__group"></div>
                            <div *ngIf="(form.value.returnInvoiceType && form.value.returnInvoiceType == 'DistributorToCompany')" class="col-sm-6 col-xl-6 form-group m-form__group">
                                <label>Select Distributor </label>
                                <span class="text-danger">*</span>
                                <div class="input-group">
                                    <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                                        formControlName="returnInvoicedByDistributor"
                                        [suggestions]="filteredDistributors"
                                        (completeMethod)="filterDistributors($event)" field="name" [dropdown]="true">
                                        <ng-template let-distributor pTemplate="distributor">
                                            <div class="distributor-item">
                                                <div>{{distributor.name}}</div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div *ngIf="form.controls.returnInvoicedByDistributor.invalid && (form.controls.returnInvoicedByDistributor.dirty || form.controls.returnInvoicedByDistributor.touched)"
                                    class="text text-danger">
                                    <div *ngIf="form.controls.returnInvoicedByDistributor.errors.required">
                                        Distributor is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-xl-12 form-group m-form__group">

                                <p-table [scrollable]="true" scrollHeight="400px" #dt [value]="invoiceItems" [rows]="10"
                                    [paginator]="true" [(selection)]="selectedInvoiceItems" [rowHover]="true"
                                    dataKey="id"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 3rem">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th style="width:25%">Product</th>
                                            <th style="width:25%">Quantity</th>
                                            <th style="width:25%">Price</th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr>
                                            <td>
                                                <p-tableCheckbox [value]="item"></p-tableCheckbox>
                                            </td>
                                            <td>{{item.product.name}}</td>
                                            <td>{{item.quantity}}</td>
                                            <td>{{item.price}}</td>
                                            <td>
                                                <button style="margin-right:10px" pButton pRipple icon="pi pi-pencil"
                                                    class="p-button p-button-success m-r-2"
                                                    (click)="editItem(item)"></button>
                                                <button pButton pRipple icon="pi pi-trash"
                                                    class="p-button p-button-danger"
                                                    (click)="deleteItem(item)"></button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="paginatorleft">
                                        <button style="float: left;" type="button" class="btn btn-primary me-1"
                                            (click)="addNew();$event.preventDefault()">Add Item</button>
                                        <button [disabled]="selectedInvoiceItems.length == 0" style="float: left;"
                                            type="button" class="btn btn-danger me-1"
                                            (click)="deleteSelectedItems();$event.preventDefault()">Delete
                                            Selected</button>
                                    </ng-template>
                                </p-table>

                            </div>
                        </div>
                        <div class="card-footer" style="text-align:right;">
                            <button type="button" class="btn btn-primary me-1" (click)="saveInvoice()">Save Invoice
                                Details</button>
                            <button type="button" class="btn btn-light"
                                (click)="activeModal.dismiss('Closed')">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<p-dialog [(visible)]="itemDialog" [style]="{width: '450px',height:'800px'}" header="Invoice Item Details"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field  p-t-10">
            <label for="name">Select Product</label>
            <p-dropdown [scrollHeight]="500" [options]="products" [(ngModel)]="invoiceItem.product" optionLabel="name"
                (onChange)="onProductChanged($event)"></p-dropdown>
            <small class="p-invalid" *ngIf="!submitted && !invoiceItem.product">Product is mandatory</small>
        </div>
        <div class="p-formgrid p-grid p-t-10">
            <div class="p-field p-col">
                <label for="quantity">Quantity</label>
                <p-inputNumber id="quantity" (onInput)="quantityChanged($event)"
                    [(ngModel)]="invoiceItem.quantity"></p-inputNumber>
                <small style="color:red" class="p-invalid" *ngIf="!submitted && invoiceItem.quantity <= 0">Quantity
                    should be greater than 0.</small>
            </div>
            <div class="p-field p-col  p-t-10">
                <label for="price">Price</label>
                <p-inputNumber [disabled]="true" id="price" [(ngModel)]="invoiceItem.price" mode="currency"
                    currency="INR" locale="en-US"></p-inputNumber>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveItem()"></button>
    </ng-template>
</p-dialog>