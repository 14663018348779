import { Observable, Subject } from "rxjs";
import { Menu } from '../services/nav-service.service';

export default class DistributorMenu {


  public static MENUITEMS: Menu[] = [
    {
      title: 'Dashboard', icon: 'home', type: 'link', active: true,path: 'dashboard',
      route:{
        path: 'dashboard',
        data: {
          breadcrumb: {
            label: 'Dashboard',
          },
          role: 'Distributor',
          animation: 'slideInAnimation'
        },
        children: []
      },
    },
    {
      title: 'All Invoices', icon: 'file-plus', type: 'link', active: false, path: 'invoices',
      route:{
        children: [
          {
            pathMatch: 'full',
            data: {
              title:"All Invoices",
              breadcrumb: {
                label: "All Invoices",
                info: 'icofont icofont-files'
              },
              role: 'Distributor',
              animation: 'slideInAnimation'
            }
          },
        ]
      }
    },
    {
      title: 'All Returns', icon: 'file-plus', type: 'link', active: false, path: 'product-returns',
      route:{
        children: [
          {
            pathMatch: 'full',
            data: {
              title:"All Returns",
              breadcrumb: {
                label: "All Invoices",
                info: 'icofont icofont-files'
              },
              role: 'Distributor',
              animation: 'slideInAnimation'
            }
          },
        ]
      }
    },
  ]

  public static getMenu(){
    return DistributorMenu.MENUITEMS
  }
  
}