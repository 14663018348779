import { Invoice, User, Distributor } from './../../../types/types';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { AddInvoiceComponent } from '../add-invoice/add-invoice.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbDate, NgbDateParserFormatter, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../app.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ColumnMode, DatatableComponent, NgxDatatableModule } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-list-invoice',
  templateUrl: './list-invoice.component.html',
  styleUrls: ['./list-invoice.component.scss']
})
export class ListInvoiceComponent extends ListBaseComponent<Invoice> implements OnInit {

  moment = moment

  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('DatatableComponent') ngxDatatable: DatatableComponent;

  private currentComponentWidth
  
  invoiceRegistrationObserver: Subscription;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';

  bShowFilters = false

  hoveredDate: NgbDate | null = null;

  invoiceNumber:string = '';
  fromDate: NgbDate | null = undefined
  toDate: NgbDate | null = undefined
  verifiedInvoice: boolean = undefined

  today = new Date()

  loggedInUser : User = undefined
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal, protected formatter: NgbDateParserFormatter,protected renderer: Renderer2, private authService: AuthService) {
      super(http,breadcrumbService,appService,offcanvasService,router,route, ); 

      this.authService.getCurrentUser().subscribe({
        next:((user) => {
          this.loggedInUser = user
        })
      })
  }

  ngOnInit(): void {
    
    this.appService.setTitle("All Invoices")
    this.appService.setMenuItems([{label:'All Invoices',disabled:true,routerLink: '/invoices',iconClass:"pi pi-users"}])

    this.pageCallback({ offset: 0 });

    this._message.subscribe((message) => (this.message = message));
    this._message.pipe(debounceTime(5000)).subscribe(() => {
			this.alertError = false;
		});
    this.fromDate = undefined;
		this.toDate = undefined;

  }

  ngAfterViewChecked() {
    // Check if the table size has changed,
    if (this.ngxDatatable && this.ngxDatatable.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.ngxDatatable.columnMode = ColumnMode.force;
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.ngxDatatable.recalculate();
      
    }
  }

  showFilters(){
    this.bShowFilters = !this.bShowFilters
    if( !this.bShowFilters){
      this.clearFilter();
    }
    setTimeout(() => {
      this.ngxDatatable.recalculate()
    }, 100);
  }
  clearFilter(){
    const dateRangeInput = document.getElementById("dateRangeInput");
    this.fromDate = undefined;
    this.toDate = undefined;
    this.verifiedInvoice = undefined
    this.invoiceNumber = ""
    if( dateRangeInput )
      this.renderer.setProperty(dateRangeInput, 'value', "");
    super.clearFilter();
  }

  applyFilterAfterDelay(){
    setTimeout(() => {
      this.applyFilter();
    }, 10); 
  }

  populateParamsForQuery(params: HttpParams): HttpParams{

    if( this.invoiceNumber.length > 0){
      params = params.set('invoiceNumber', `${this.invoiceNumber}`);
    }
    if( this.fromDate && this.toDate){
      params = params.set('invoiceFromDate', `${this.formatter.format(this.fromDate)}`);
      params = params.set('invoiceToDate', `${this.formatter.format(this.toDate)}`);
    }
    if( this.verifiedInvoice !== undefined ){
      params = params.set('verified', `${this.verifiedInvoice}`);
    }
    
    return params;
  }

  onActivate(event){
    if(event.type == 'click') {
      console.log(event.row);
      this.editInvoice(event.row)
    }
  }

  addNewInvoice(){
    
    const modalRef = this.modalService.open(AddInvoiceComponent,{ centered: true,size: 'xl'});
    modalRef.componentInstance.name = 'Add / Edit Invoice';
    
    if( this.invoiceRegistrationObserver ){
      this.invoiceRegistrationObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/

    this.invoiceRegistrationObserver = modalRef.componentInstance.invoiceEntry.subscribe((invoice) => {
      this.reloadTable()
    });
    
  }

  editInvoice(invoice){
    const modalRef = this.modalService.open(AddInvoiceComponent,{ centered: true,size: 'xl'});
    modalRef.componentInstance.name = 'Edit Invoice';
    
    if( this.invoiceRegistrationObserver ){
      this.invoiceRegistrationObserver.unsubscribe();
    }
  
    /*if( values.destinationAirport != undefined && values.destinationAirport.length != 0){
      modalRef.componentInstance.airport = values.destinationAirport;
    }*/
    modalRef.componentInstance.invoiceId = invoice.id
    this.invoiceRegistrationObserver = modalRef.componentInstance.invoiceEntry.subscribe((invoice) => {
      this.reloadTable()
    });
    
  }

  protected get loadingUrl(){
    return `${environment.serverUrl}/invoice`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/invoice/export`;
  }

  protected get exportFileName(){
    return `Invoice Report`;
  }

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
    let parsed = '';
    if(this.fromDate && this.toDate) {
      parsed += this.formatter.format(this.fromDate);
      parsed += ' to ' + this.formatter.format(this.toDate);
    }
    const dateRangeInput = document.getElementById("dateRangeInput");
    this.renderer.setProperty(dateRangeInput, 'value', parsed);
    this.applyFilter();
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

  getRowClass = (row) => {
    return this.loggedInUser.userType !== 'Distributor' ? (row.verified ? 'invoice-verified' : 'invoice-unverified') : 'invoice-none'
  }

  getInvoicedBy(row){
    if( row.invoiceType === "CompanyToDistributor" || row.invoiceType === "CompanyToDealer" ){
      return "BNA Solutions (C)"
    }
    else if( row.invoiceType === "DistributorToDealer" ){
      return `${row.invoicedByDistributor?.name} (DI)`
    }
    else{
      return `${row.invoicedByDealer?.name} (DL)`
    }
  }

  getInvoicedTo(row){
    if( row.invoiceType === "CompanyToDistributor" ){
      return `${row.distributor?.name} (DI)`
    }
    else if( row.invoiceType === "DistributorToDealer" || row.invoiceType === "CompanyToDealer"){
      return `${row.dealer?.name} (DL)`
    }
    else{
      return "N/A"
    }
  }
}
