import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table'
import { ToolbarModule } from 'primeng/toolbar'
import { ButtonModule } from 'primeng/button'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AddDealerComponent } from './add-dealer/add-dealer.component';
import { DealerDetailComponent } from './dealer-detail/dealer-detail.component';
import { ListDealerComponent } from './list-dealer/list-dealer.component';
import { TabViewModule } from 'primeng/tabview'
@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    TableModule,
    ProgressSpinnerModule,
    ToolbarModule,
    TabViewModule,
    AutoCompleteModule,
    ButtonModule
  ],
  declarations: [
    AddDealerComponent,
    DealerDetailComponent,
    ListDealerComponent
  ]
})
export class DealerModule { }
