<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<div *ngIf="loading" style="position:absolute;z-index: 10000;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;background: rgb(0,0,0,0.1);">
    <p-progressSpinner styleClass="w-4rem h-4rem"  strokeWidth="3"  fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>
<div class="card p-15">
    <h5>Search Dealers /  Retailers</h5>
    <p-table #dt2 [value]="dealers" dataKey="id"
        [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines" [loading]="false" [(selection)]="selectedDealers"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['name','mobileNumber','city.name','city.district.name','verified']" [tableStyle]="{'min-width':'75rem'}">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="width:22%">Name</th>
                <th style="width:22%">Mobile Number</th>
                <th style="width:22%">City</th>
                <th style="width:22%">District</th>
                <th style="width:12%">Verified</th>
            </tr>
            <tr>
                <th style="width: 4rem"></th>
                <th>
                    <p-columnFilter type="text" field="name"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="mobileNumber"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="city.name"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="city.district.name"></p-columnFilter>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dealer>
            <tr>
                <td>
                    <p-tableCheckbox [value]="dealer"></p-tableCheckbox>
                </td>
                <td>
                    {{dealer.name}}
                </td>
                <td>
                    <span class="image-text">{{dealer.mobileNumber}}</span>
                </td>
                <td>
                    <span class="image-text">{{dealer.city?.name}}</span>
                </td>
                <td>
                    <span class="image-text">{{dealer.city?.district?.name}}</span>
                </td>
                <td>
                    <i class="pi" [ngClass]="{'true-icon pi-check-circle': dealer.verified, 'false-icon pi-times-circle': !dealer.verified}"></i>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No dealer found.</td>
            </tr>
        </ng-template>
    </p-table>
    <div class="card-footer" style="text-align:right;">
        <button type="submit" class="btn btn-primary me-1" (click)="addSelecteddDealer()">Add Dealer</button>
        <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Closed')">Cancel</button>
    </div>
</div>